import React, { useState ,useEffect}  from "react";
import logo from '../assets/images/logo.png'
import special_message from '../assets/images/special_message.png'
import {useNavigate } from 'react-router-dom'
import cal from '../assets/icons/cal.svg'
import '../assets/styles/registerCss/register.hebrew.css';
import down from '../assets/img/down.png'
export default function Registerrtl(){

    const navigate = useNavigate();
    const [err,setErr] = useState('');
    const [passerr,setPassErr] = useState('');
    const [type,setType] = useState(' ');
    const [typeValidation,settypeValidation] = useState('');

    const [terms,setTerms] = useState('');
    const [termsValidation,setTermsValidation] = useState('');

    const [org_orgValidation,setOrg_OrgValidation] = useState('');
    const [org_organization,setOrg_organization] = useState('');

    const [usr_birthdateValidation,setusr_birthdateValidation] = useState('');
    const [usr_langValidation,setusr_langValidation] = useState('');

    const [org_jobValidation,setOrg_jobValidation] = useState('');
    const [org_job,setOrg_job] = useState('');

    const [org_firstNameValidation,setOrg_firstNameValidation] = useState('');
    const [org_firstName,setOrg_firstName] = useState('');

    const [org_lastNameValidation,setOrg_lastNameValidation] = useState('');
    const [org_lastName,setOrg_lastName] = useState('');

    const [org_emailValidation,setOrg_emailValidation] = useState('');
    const [org_email,setOrg_email] = useState('');

    const [org_passwordValidation,setOrg_passwordValidation] = useState('');
    const [org_password,setOrg_password] = useState('');

    const [org_password2Validation,setOrg_password2Validation] = useState('');
    const [org_password2,setOrg_password2] = useState('');

    const [logo2,setLogo2] = useState('');

    const [birthdate,setBirthDate] = useState('');
    const [lang,setLang] = useState('');

    const [translate,setTransate] = useState()   
 
    const [msg,setMsg] =useState('')
    const [usr_firstNameValidation,setUsr_firstNameValidation] = useState('');
    const [usr_firstName,setUsr_firstName] = useState('');

    const [usr_lastNameValidation,setUsr_lastNameValidation] = useState('');
    const [usr_lastName,setUsr_lastName] = useState('');

    const [usr_emailValidation,setUsr_emailValidation] = useState('');
    const [usr_email,setUsr_email] = useState('');

    const [usr_passwordValidation,setUsr_passwordValidation] = useState('');
    const [usr_password,setUsr_password] = useState('');

    const [usr_password2Validation,setUsr_password2Validation] = useState('');
    const [usr_password2,setUsr_password2] = useState('');


    const focusBirthdate=(e)=>{
      document.getElementById('date').type='date'
    }
    
    const SignIn =(e)=>{
      e.preventDefault();
        //debugger;
        let validation=true;
        setErr(false)
        setPassErr(false)
        if(type == " "){
          settypeValidation("1px solid red")
          validation=false;
        }
        if(terms!=true)
        {
        setTermsValidation("1px solid red")
        validation=false;
        }
        else
        setTermsValidation("")

    if(type=="Organisation")
    {
        if(org_organization.trim()=="")
        {
        setOrg_OrgValidation("1px solid red")
        validation=false;
        }
        else
        setOrg_OrgValidation("")
//debugger;
        if(org_job.trim()=="")
        {
          setOrg_jobValidation("1px solid red")
        validation=false;
        }
        else
        setOrg_jobValidation("")

       

        if(org_firstName.trim()=="")
        {
        setOrg_firstNameValidation("1px solid red")
        validation=false;
        }
        else
        setOrg_firstNameValidation("")

        if(org_lastName.trim()=="")
        {
        setOrg_lastNameValidation("1px solid red")
        validation=false;
        }
        else
        setOrg_lastNameValidation("")

        if(org_email.trim()=="")
        {
        setOrg_emailValidation("1px solid red")
        validation=false;
        }
        else
        setOrg_emailValidation("")

        if(org_password.trim()=="")
        {
        setOrg_passwordValidation("1px solid red")
        validation=false;
        }
        else
        setOrg_passwordValidation("")

        if(org_password2.trim()=="")
        {
        setOrg_password2Validation("1px solid red")
        validation=false;
        }
        else
        setOrg_password2Validation("")

        if(org_password!=org_password2)
        {
            validation=false;
            setOrg_passwordValidation("1px solid red")
            setOrg_password2Validation("1px solid red")
        }
        if(org_password.length<8 || !containsNumbers(org_password) || !containsLetters(org_password))
        {
          //debugger;
          if(org_password==org_password2 && org_password2.trim()!="")
          {
          setPassErr(true)
            validation=false;
            setOrg_passwordValidation("1px solid red")
          }
        }
    }
    else
    {
      //debugger;
      // if(birthdate.trim()=="")
      // {
      //   setusr_birthdateValidation("1px solid red")
      // validation=false;
      // }
      // else
      // setusr_birthdateValidation("")
      // if(lang.trim()=="")
      //   {
      //     setusr_langValidation("1px solid red")
      //   validation=false;
      //   }
      //   else
      //   setusr_langValidation("")

        if(usr_firstName.trim()=="")
        {
        setUsr_firstNameValidation("1px solid red")
        validation=false;
        }
        else
        setUsr_firstNameValidation("")

        if(usr_lastName.trim()=="")
        {
        setUsr_lastNameValidation("1px solid red")
        validation=false;
        }
        else
        setUsr_lastNameValidation("")

        if(usr_email.trim()=="")
        {
        setUsr_emailValidation("1px solid red")
        validation=false;
        }
        else
        setUsr_emailValidation("")

        if(usr_password.trim()=="")
        {
        setUsr_passwordValidation("1px solid red")
        validation=false;
        }
        else
        setUsr_passwordValidation("")

        if(usr_password2.trim()=="")
        {
        setUsr_password2Validation("1px solid red")
        validation=false;
        }
        else
        setUsr_password2Validation("")

        if(usr_password!=usr_password2)
        {
            validation=false;
            setUsr_passwordValidation("1px solid red")
            setUsr_password2Validation("1px solid red")
        }

        
    }

    if(validation==true)
    {
        if(type=="Organisation")
        {
            const Register ={'Email':org_email,'OrgName':org_organization,'FirstName':org_firstName,'LastName':org_lastName,'Password':org_password,'Job':org_job}
              fetch('https://api.readupp.com/Login/RegisterOrg',{
              //   fetch('https://localhost:44318/Login/RegisterOrg',{
                    method:'POST',
                    headers:{"Content-Type":"application/json"},
                    body:JSON.stringify(Register)
                })
                .then(response => response.json())
                .then(data =>
                {
                    //debugger;
                    setErr(!data.ok);
                    if(data.ok)
                    {
                    localStorage.loginDate = Date.now()
                    localStorage.userId = data.userId;
                    localStorage.role = data.role;
                    localStorage.email = data.email;
                        navigate('/booksrtl')
                }
                
    })
    .catch(() => {
        //debugger;
        setErr(true);
      });

        }
        else
        {
            const Register ={'Email':usr_email,'FirstName':usr_firstName,'LastName':usr_lastName,'Password':usr_password,'orgId':0,'guid':getSearchParams('param'),
        }
               fetch('https://api.readupp.com/Login/RegisterUser',{
        //    fetch('https://localhost:44318/Login/RegisterUser',{
                method:'POST',
                headers:{"Content-Type":"application/json"},
                body:JSON.stringify(Register)
            })
            .then(response => response.json())
            .then(data =>
            {
                //debugger;
                if(!data.isSucceed){
                  setMsg(data.message)
                } 
             else   if(data.ok)
                {
                localStorage.loginDate = Date.now()
                localStorage.userId = data.userId;
                localStorage.role = data.role;
                localStorage.email = data.email;
                localStorage.exp="none";
                    navigate('/booksrtl')
                }
            })
            .catch(() => {
                setErr(true);
              });
        }
    }

    }

    const toggleDropdown = () => {
      const dropdown = document.getElementById("dropdown");
      if (dropdown != null) {
        dropdown.classList.toggle("show");
      }
    };
    const changeRegType =(type)=>{
        setType(type)
     
       
        if (type === "Private user") {
            document.getElementById("privat-user").style.display = "block";
            document.getElementById("organisation").style.display = "none";
         //   userSubscription("YES")
          } else {
            document.getElementById("privat-user").style.display = "none";
            document.getElementById("organisation").style.display = "block";
      
          }

    }


    const containsNumbers=(str)=> {
      return /\d/.test(str);
    }

    const containsLetters=(str)=> {
      return /[a-zA-Z]/.test(str);
    }

    const getSearchParams=(k)=> {
      var p = {};
      window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (s, k, v) { p[k] = v })
      return k ? p[k] : p;
  }
  const param = getSearchParams('param');
 
  useEffect(() => {

    //  fetch('https://localhost:44318/api/UserSettings/GetReadupHe')
    fetch('https://api.readupp.com/api/UserSettings/GetReadupHe')
    .then(async response => {
        const data = await response.json();
        //debugger;
        setTransate(data);
        
    })
    .catch(error => {
        
    });


    //debugger;
    if(window.location.href.includes('param'))
    {
      changeRegType('Private user');
      document.getElementById('selectOrgUsr').selectedIndex = 1;
      document.getElementById('selectOrgUsrUp').style.visibility = 'hidden';

    }

     // fetch('https://localhost:44318/Login/GetIcon?guid='+getSearchParams('param'))
      fetch('https://api.readupp.com/Login/GetIcon?guid=' + getSearchParams('param'))
        .then(async response => {
            const data = await response.json();
            setLogo2(data.line);
        })
        .catch(error => {
            this.setState({ errorMessage: error.toString() });
            console.error('There was an error!', error);
        });

    },[])
    // const userSubscription = (type) => {
    
    //     if (type === "YES") {
    //       document.getElementById("subscription_message").style.display = "none";
    //       document.getElementById("not_organisation").style.display = "block";
    //       if(document.getElementsByClassName("focus")[0]!=undefined)
    //       document.getElementsByClassName("focus")[0].classList.remove('focus')
    //     } else {
    //       document.getElementById("subscription_message").style.display = "block";
    //       document.getElementById("not_organisation").style.display = "none";
    //       if(document.getElementsByClassName("focus")[0]!=undefined)
    //       document.getElementsByClassName("focus")[0].classList.remove('focus')
    //      }
    //   };


    return( <> 
    
    
    <div className="Rhregister" style={{ minHeight: '100vhimportant', overflowy: 'auto', width: 'auto' , direction:"Rhtl"}}>
      <div className="Rhinner">
      

        <div className="Rhlang" id="myDropdown" onClick={toggleDropdown}>
          <img src={down} alt="" />
          <p>עברית</p>
          <div className="Rhdropdown" id="dropdown">
          {param != undefined&& <a href={`/register?param=${param}`}>English</a>}
          {param == undefined&&  <a href={`/register`}>English</a>}
          </div>
        </div>
        <div className="RhlogoImg">
          <a href="#" className="Rhlogo"><img src={logo2 != '' ? logo2 : logo}  alt="" /></a>
        </div>

        {/* <div
          className="Rhd-flex flex-column justify-content-center align-items-center w-100 mt-4 mt-md-2"
        >
          <div>
            <img style={{ 'max-width': '120px' }} src={logo2 != '' ? logo2 : logo} alt="" />
          </div>
          <h3 className="Rhcolor1 mb-0 mt-4">{translate != null ? translate[37].value : 'Sign in'}</h3>
        </div> */}
        <h1>{translate != null ?translate[118].value : 'Create a new account'}</h1>

       <form action="#" onSubmit={(e)=>SignIn(e)}>
       


          <div className="Rhcustom-select" id="selectOrgUsrUp" style={{ border: typeValidation }}>


            <select 
              onChange={(e) => changeRegType(e.target.value)}

              id="selectOrgUsr"
            >
              <option value="User type">סוג חשבון</option>
              <option value="Organisation">{translate != null ?translate[124].value : 'Private user'}</option>
              <option value="Private user">{translate != null ?translate[119].value : 'Private user'}</option>
            </select>
            <div className="Rhselect-icon">
              <img src={down} alt="" />
            </div>
          </div>

      

      <div id="privat-user" >
        {/* <div className="Rhrow gy-3"> */}


        <div id="subscription_message" className="Rhcol-12 mt-4">
              {/* <div className="Rhspecial_message">
              <div className="Rhcontainer-fluid p-3">
                <div className="Rhrow">
                  <div className="Rhcol-7 pe-0">
                    <p className="Rhf14 mb-0 color1 fw500">
                      We’re Sorry.. in order to access the book
                      database, you must establish a subscription at
                      the
                      <span className="Rhtext-decoration-underline">
                        The Central Library for the Blind, Visually
                        Impaired and Handicapped.
                      </span>
                      After the subscription is approved, please log
                      in again.
                    </p>
                  </div>
                  <div className="Rhcol-5 ps-0 pe-1">
                    <img
                      className="Rhw-100"
                      src={special_message}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div> */}
          </div> 

          {/* <div

            id="not_organisation"
            className="Rhcol-12"
          > */}
          
                <input style={{ border: usr_firstNameValidation }}
                  className="Rhinput"
                  type="text"
                  name="fName"
                  id="fName"
                  value={usr_firstName} onChange={(e) => setUsr_firstName(e.target.value)}
                  placeholder={translate != null ?translate[120].value : 'First name'}
                />
            
                <input style={{ border: usr_lastNameValidation }}
                  className="Rhinput"
                  type="text"
                  name="Surname"
                  id="Surname"
                  value={usr_lastName} onChange={(e) => setUsr_lastName(e.target.value)}
                  placeholder={translate != null ?translate[121].value : 'last name'}
                />
           
                <input style={{ border: usr_emailValidation }}
                  className="Rhinput"
                  type="text"
                  name="Email"
                  id="Email"
                  value={usr_email} onChange={(e) => setUsr_email(e.target.value)}
                  placeholder={translate != null ?translate[88].value : 'Email'}
                />
            


          
                <input style={{ border: usr_passwordValidation }}
                  className="Rhinput"
                  type="password"
                  name="Password"
                  id="Password"
                  value={usr_password} onChange={(e) => setUsr_password(e.target.value)}
                  placeholder={translate != null ?translate[87].value : 'Password'}
                />
           
                <input style={{ border: usr_password2Validation }}
                  className="Rhinput"
                  type="password"
                  name="Cpassword"
                  id="Cpassword"
                  value={usr_password2} onChange={(e) => setUsr_password2(e.target.value)}
                  placeholder={translate != null ?translate[122].value : 'Password  (again)'}
                />
           
      </div>

      <div id="organisation" >
      
            <input style={{ border: org_orgValidation }}
              className="Rhinput"
              type="text"
              name="Name"
              id=""
              value={org_organization} onChange={(e) => setOrg_organization(e.target.value)}
              placeholder={translate != null ?translate[125].value : 'Organisation name'}
            />
          
             <input style={{ border: org_jobValidation }}
              className="Rhinput"
              type="text"
              name="Position"
              id="Position"
              value={org_job} onChange={(e) => setOrg_job(e.target.value)}
              placeholder={translate != null ?translate[126].value : 'Position'}
            />
         
            <input style={{ border: org_firstNameValidation }}
              className="Rhinput"
              type="text"
              name="fnameOfInsti"
              id="fnameOfInsti"
              value={org_firstName} onChange={(e) => setOrg_firstName(e.target.value)}
              placeholder={translate != null ?translate[127].value : 'First name of organization representative'}
            />
          
            <input style={{ border: org_lastNameValidation }}
              className="Rhinput"
              type="text"
              name="lnameOfInsti"
              id="lnameOfInsti"
              value={org_lastName} onChange={(e) => setOrg_lastName(e.target.value)}
              placeholder={translate != null ?translate[128].value : 'Last name of organization representative'}
            />
      
            <input style={{ border: org_emailValidation }}
              className="Rhinput"
              type="text"
              name="email"
              id="email"
              value={org_email} onChange={(e) => setOrg_email(e.target.value)}
              placeholder={translate != null ?translate[88].value : 'Email'}
            />
       




        
            <input style={{ border: org_passwordValidation }}
              className="Rhinput"
              type="password"
              name="Password"
              id="Password"
              value={org_password} onChange={(e) => setOrg_password(e.target.value)}
              placeholder={translate != null ?translate[87].value : 'Password'}
            />

            <input style={{ border: org_password2Validation }}
              className="Rhinput"
              type="text"
              name=""
              id=""
              value={org_password2} onChange={(e) => setOrg_password2(e.target.value)}
              placeholder={translate != null ?translate[122].value : '>Password (again) '}
            />
      
      </div>
      <div className="Rhrow">
        <button  onClick={(e) => SignIn(e)}type="submit">הרשמה</button>
            <label className="Rhcustom-checkbox" style={{ border: termsValidation }}>
              <input type="checkbox" value={terms} onChange={(e) => setTerms(e.target.checked)} />
              <span className="Rhcheckmark"></span>
              <p>{translate != null ?translate[123].value : 'I agree to the'} <a href="#">{translate != null ?translate[116].value : 'terms of use'}</a></p>
            </label>
            

            {/* <button onClick={() => SignIn()}>Register</button> */}
          </div>
    
      {err ? <div className="Rhcol-12">
        <span className="Rhw-100 red" type="text" name>Email allready exist or other details incorrect</span>
      </div> : null}
    
      {passerr ? <div className="Rhcol-12">
        <span className="Rhw-100 red" type="text" name>Password must be minimum 8 characters and contain letters and numbers</span>
      </div> : null}
      <div className="Rcol-12">
        <span className="Rw-100 red" type="text" name>{msg}</span>
      </div>

        <div className="Rhline"></div>
        <p className="RhdontHaveAccount"> {translate != null ?translate[130].value : 'Already have an account? '}<a href="/loginrtl">{translate != null ?translate[129].value : '  Login '}</a></p>
      


    </form>
  </div >
    </div >
    
    
    
    
    </>)
}
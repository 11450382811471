import back from '../assets/icons/back.svg'

import Nav from "./NavBar";
// import Footer from './Footer';

export default function Help() {

    const Back = () => {
        window.history.go(-1)
    }


    return (<>

        <Nav nav="/helprtl" />
        <img
            class="d-none d-lg-block back pointer" style={{ 'width': '50px', 'margin-left': '3%' }}
            src={back} onClick={() => Back()}
            alt="" />

        <div class="register_container">
            <div class="register_inner_container">
                <div class="container-fluid">

                    <div class="row">
                        <div class="col-11 col-md-12 mx-auto">



                            <div>

                                Help</div>




                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <Footer lang="en"/> */}

    </>
    )

}
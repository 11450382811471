import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom'

import hamburger from '../assets/icons/hamburger.svg'
import logolight from '../assets/images/logo-light.png'
import avatar from '../assets/icons/avatar.svg'
import search from '../assets/icons/search.svg'
import accessibility from '../assets/icons/accessibility-light.svg'
import back from '../assets/icons/back.svg'
import cal from '../assets/icons/cal.svg'
import book from '../assets/icons/book.svg'
import timer from '../assets/icons/timer.svg'

import needHelp from "../assets/images/needHelp.svg"
import backArrow from "../assets/img/backArrow.svg";
import calander from "../assets/img/calander.svg";
import myAccount1 from "../assets/img/myAccount1.svg";
import myAccount2 from "../assets/img/myAccount2.svg";
import myAccount3 from "../assets/img/myAccount3.svg";
import circleTick from "../assets/img/circleTick.png";
import circleTickEmpty from "../assets/img/circleTickEmpty.png";
import activeUser from "../assets/img/activeUser.svg";
import subscriptionImg from "../assets/img/subscriptionImg.svg";
import copyText from "../assets/img/copyText.svg"

import emailSendIcon from "../assets/img/emailSendIcon.svg";
import plus from "../assets/img/plus.svg";
import icons8down48 from "../assets/images/icons8-down-48.png"
import Nav from "./NavBar";
import Footer from "./Footer";
import '../assets/styles/nav/home.css';
import Institute from "./Institute";

export default function Account() {

  const navigate = useNavigate();
  const location = useLocation();

  const localStorageUserId = parseInt(localStorage.getItem('userId'), 10);
  const [userId, setUserId] = useState('');
  const [isOrg, setIsorg] = useState(false)
  let role = localStorage.getItem('role');;
  //  localStorage.getItem('role');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState('');
  const [gender, setGender] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [file, setSelectedFile] = useState(null);
  const [translate, setTransate] = useState()
  const [orgid, setOrgid] = useState('');
  const [subscribeLastDateString, setSubscribeLastDateString] = useState('');
  const [chaptersCounter, setChaptersCounter] = useState('');
  const [readTimer, setReadTimer] = useState('');

  const [msg, setMsg] = useState('');


  const closePurchase = () => {
    document.getElementById('exampleModal').style.display = 'none';
    document.getElementById('exampleModal').style.opacity = '0';

  }

  const showMenu = () => {
    document.getElementById("mobileMenu").style.right = "0%";
  }
  const closeMenu = () => {
    document.getElementById("mobileMenu").style.right = "-100%";
  }
  const showDropDown = () => {
    document.getElementById("myDropdown").classList.toggle("show");
  }


  const purchasePopUp = (months, price) => {
    //debugger;
    document.getElementById('exampleModal').style.display = 'block';
    document.getElementById('exampleModal').style.opacity = '1';

    document.getElementById('tranzila').setAttribute('src', 'https://direct.tranzila.com/vayikra22/iframenew.php?sum=' + price + '&currency=2&email=' + localStorage.email + '&fail_url_address=https://direct.tranzila.com/vayikra22/iframenew.php&success_url_address=http://104.238.214.197:8087/success');

    localStorage.monthreq = months;
  }
  const openMyAccount = (evt, tabName) => {

    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    if (tabName != null) {
      let tabElement = document.getElementById(tabName);
      if (tabElement) {
        document.getElementById(tabName)
        tabElement.style.display = "block";
        evt.currentTarget.className += " active";
      }
    }
  }




  const Back = () => {
    window.history.go(-1)
  }

  // const Logout = () => {
  //   localStorage.removeItem('loginDate')
  //   navigate('/');
  // }

  const Copyrights = () => {
    navigate('/Privacy');
  }
  const Contacts = () => {
    navigate('/Contacts');
  }
  const Help = () => {
    navigate('/Help');
  }
  const handleBlur = (e) => {
    if (e.target.value === '') {
      e.target.placeholder = e.target.name.charAt(0).toUpperCase() + e.target.name.slice(1);
    }
  };

  const handleFocus = (e) => {
    e.target.placeholder = '';
  };
  const getBase64 = (file) => {

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let filebase64 = reader.result;

      const Register = {
        'Email': email, 'FirstName': firstName, 'LastName': lastName, 'Password': password, 'Phone': tel, 'Phone': tel, 'Gender': gender, 'Country': country, 'Address': address,
        'City': city, 'userId': userId, 'logo': filebase64
      }
      fetch('https://api.readupp.com/Login/UpdateRegisterUser', {
        //       fetch('https://localhost:44318/Login/UpdateRegisterUser',{
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(Register)
      })
        .then(response => response.json())
        .then(data => {
          //debugger;
          if (data.ok) {
            localStorage.loginDate = Date.now()
            setMsg('Data saved')
          }

        })
        .catch(() => {
          setMsg('Error')
        });
    }
  }
  const Save = (event) => {

    let passwordsMatch = true;
    for (let i = 0; i < Math.max(password.length, password2.length); i++) {
      if (password[i] !== password2[i]) {
        console.log(`Mismatch at character ${i + 1}: "${password[i]}" vs "${password2[i]}"`);
        passwordsMatch = false;
        break;
      }
    }
  
    if (passwordsMatch) {
      //debugger;
      event.preventDefault(); // מונע את הרענון של הדף

      if (file != null) {
        getBase64(file); // prints the base64 string
      }

      const Register = {
        'Email': email, 'FirstName': firstName, 'LastName': lastName, 'Password': password, 'Phone': tel, 'Phone': tel, 'Gender': gender, 'Country': country, 'Address': address,
        'City': city, 'userId': userId
      }
      fetch('https://api.readupp.com/Login/UpdateRegisterUser', {
        //    fetch('https://localhost:44318/Login/UpdateRegisterUser',{
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(Register)
      })
        .then(response =>
          response.json())
        .then(data => {
          //debugger;
          if (data.ok) {
            localStorage.loginDate = Date.now()
            setMsg('Data saved')
          }

        })

        .catch(() => {
          setMsg('Error')
        });
    }
    else {
      event.preventDefault(); 
      setMsg('password dont Same !')
    }
  }
  const focusBirthdate = (e) => {

    document.getElementById('birthdate').type = 'date'
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'fname':
        setFirstName(value);
        break;
      case 'lName':
        setLastName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'phone':
        setTel(value);
        break;
      case 'New password':
        setPassword(value);
        break;
      case 'New password (again)':
        setPassword2(value);
        break;
      default:
        break;
    }
  };
  const convertTimeToHoursAndMinutes = (time) => {
    if (time) {
      const [hours, minutes] = time.split(':').map(Number);
      return `${hours}:${minutes.toString().padStart(2, '0')}`; // Ensure two digits for minutes
    }
    return null; // Optional: return null if time is not provided
  };



  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = parseInt(params.get('id'), 10);

    if (id) {
      setUserId(id);
      if (id !== localStorageUserId) {
        setIsorg(false);
      } else if (role === 'Admin' || role === 'Organization Manager') {
        setIsorg(true);
      }
    } else {

      if (role === 'learning central manager') {
        setUserId(localStorageUserId);
      }
      if (role === 'Admin' || role === 'Organization Manager') {
        setUserId(localStorageUserId);
        setIsorg(true);
      }
      else {
        setUserId(localStorageUserId);
      }
    }
  }, [location.search, localStorageUserId, role]);





  useEffect(() => {

    //  fetch('https://localhost:44318/api/UserSettings/GetReadupEn')
    fetch('https://api.readupp.com/api/UserSettings/GetReadupEn')
      .then(async response => {
        const data = await response.json();
        //debugger;
        setTransate(data);


      })
      .catch(error => {

      });

    if (userId) {
      fetch(`https://api.readupp.com/Login/GetUser?userid=${userId}`)
        .then(async response => {
          const data = await response.json();
          setFirstName(data.firstName || '');
          setLastName(data.lastName || '');
          setAddress(data.address || '');
          setEmail(data.email || '');
          setTel(data.phone || '');
          setCountry(data.country || '');
          setGender(data.gender || '');
          setCity(data.city || '');
          setOrgid(data.orgId || '')
          setPassword(data.password || '');
          setPassword2(data.password || '');
          setSubscribeLastDateString(data.subscribeLastDateString || '');
          setChaptersCounter(data.chaptersCounter || '');
          setReadTimer(data.readTimer || '');
          localStorage.loginDate = Date.now();
          if (data.birthDate) {
            document.getElementById('birthdate').valueAsDate = new Date(data.birthDate.split('T')[0]);
          }
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    }
  }, [userId]);


  return (
    <>

      <Nav nav="/accountrtl" />


      <div style={{ display: 'none' }} className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog subs_modal">
          <div className="modal-content" style={{ marginTop: '20%' }}>
            <div className="container-fluid rtl" >

              <div className="w-100">

                <iframe id="tranzila" src="https://direct.tranzila.com/vayikra22/iframenew.php?sum=1&fail_url_address=http://google.com&notify_url_address=http://google.com">
                </iframe>

                <button className="px-4 py-2 text-white bg_color1 rounded-3 border-0 mt-5" id="closeiframe" onClick={() => closePurchase()}>
                  Close
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
      {(!isOrg) && (
        <div className="mainContent" dir="ltr">
          <div className="contentArea" style={{ position: 'relative' }}>
            <div className="backPage">
              <a href="/Users" style={{ textDecoration: 'none' }}><img src={backArrow} alt="" /></a>
            </div>
            <div className="headerTop">
              <h2>{translate != null ? translate[109].value : 'my account'} </h2>

              {orgid == 1 || orgid == '' && <div className="timeStamp">
                <h3>
                  {translate != null ? translate[30].value : 'Monthly subscription'} <span className="span1">|</span> &nbsp;
                  <span className="span2"> {translate != null ? translate[133].value : 'Active until'}: {subscribeLastDateString}</span>
                </h3>
                <img src={calander} alt="" />
              </div>}

            </div>

            <div className="progress">
              <div className="myAccountList">
                <button
                  id="defaultOpenAccount"
                  className="tablinks active"

                  onClick={((event) => openMyAccount(event, 'progress'))}>
                  {translate != null ? translate[137].value : 'progress'}
                </button>
                <button
                  className="tablinks"
                  onClick={((event) => openMyAccount(event, 'editDetail'))}
                >
                  {translate != null ? translate[138].value : 'Edit details'}
                </button>
              </div>
              <div className="tabContentTop">
                <div className="tabcontent " id="progress">
                  <div className="row">
                    <div className="card">
                      <p> {translate != null ? translate[134].value : 'So far you have read'}:</p>
                      <h2>{chaptersCounter} chapters</h2>
                      <img className="bottomImg" src={myAccount1} alt="" />
                    </div>
                    <div className="card">
                      <p> {translate != null ? translate[134].value : 'So far you have read'}:</p>
                      <h2>Total time of {convertTimeToHoursAndMinutes(readTimer)} hours</h2>
                      <img className="bottomImg" src={myAccount2} alt="" />
                    </div>
                    <div className="card">
                      <p>{translate != null ? translate[135].value : 'Your longest streak'}:</p>
                      <h2 style={{ marginBottom: '21px' }}>4 days!</h2>
                      <div className="rowTick">
                        <img src={circleTick} alt="" />
                        <img src={circleTick} alt="" />
                        <img src={circleTick} alt="" />
                        <img src={circleTick} alt="" />
                        <img src={circleTickEmpty} alt="" />
                        <img src={circleTickEmpty} alt="" />
                        <img src={circleTickEmpty} alt="" />
                      </div>
                      <img
                        className="bottomImg"
                        style={{ bottom: '-20px' }}
                        src={myAccount3}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="tabcontent" id="editDetail">
                  <form action="#" onSubmit={(e) => Save(e)}>
                    <h2>{translate != null ? translate[136].value : 'Edit account information'}:</h2>
                    <input
                      type="text"
                      name="fname"
                      id="fname"
                      value={firstName}
                      // placeholder="First Name"
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      onChange={handleChange}
                    />
                    <input
                      type="text"
                      name="lName"
                      id="lName"
                      value={lastName}
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      onChange={handleChange}
                    />
                    <input
                      type="email"
                      name="email"
                      id="email"
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      onChange={handleChange}
                      value={email != null ? email : 'email'}
                    />
                    <input
                      style={{ marginBottom: '54px' }}
                      type="text"
                      name="phone"
                      id="phone"
                      value={tel || ''}
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      onChange={handleChange}
                    />
                    <h2>{translate != null ? translate[36].value : 'Change password'}</h2>
                    <input
                      type="text"
                      id="password "
                      name="New password"
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      onChange={handleChange}
                      value={password || ''}
                    />
                    <input
                      style={{ marginBottom: '45px' }}
                      type="text"
                      name="New password (again)"
                      id="password2"
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      onChange={handleChange}
                      value={password2}
                    />
                    <div className="formBtn">
                      <button type="submit">{translate != null ? translate[43].value : 'Save'}</button>
                      {msg && <p >{msg}</p>}
                      {/* <button onClick={Save}>Save</button> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}




      {(isOrg) && (
        <Institute />)}

      {/* acoount details 
<div className="mainContent" dir="ltr" >
      <div className="contentArea detailAccountTop" style={{position: 'relative', paddingBottom: '50px;'}} >
        <div className="headerTop detailAccount" >
          <h2>John Doe</h2>
          <div className="activeUsers">
            <p>24 Active users <img src={activeUser} alt="" /></p>
            <p>
              Subscription active until: 24.3.22
              <img src={subscriptionImg} alt="" />
            </p>
          </div>
        </div>
        <div className="userRegistrationLink">
          <div className="left">
            <h3>User registration link:</h3>
            <div className="copyTexting">
              <p id="paragraphToCopy">
                www.vayikra.com/ins14/sdfl;#j$;lm3456467568sdgfdfghsd
              </p>
              <img id="copyButton" src={copyText} alt="" />
            </div>
            <p id="copyNotification" className="hidden">Text copied!</p>
          </div>
          <button>
            Send registration link to users
            <img src={emailSendIcon} alt="" />
          </button>
        </div>
        <form action="#" onSubmit={(e) => Save(e)}>
          <div className="left">
            <h4>Edit account information:</h4>
            <input
              type="text"
              name="fname"
              id="fname"
              onBlur={handleBlur}
              onFocus={handleFocus}
              value="First name  of representative"
            />
            <input
              type="text"
              name="lName"
              id="lName"
              onBlur={handleBlur}
              onFocus={handleFocus}
              value="Last Name of representative"
            />
            <input
              type="text"
              name="Position"
              id="Position"
              onBlur={handleBlur}
              onFocus={handleFocus}
              value="Position"
            />
            <input
              type="text"
              name="email"
              id="email"
              onBlur={handleBlur}
              onFocus={handleFocus}
              value="Email address"
            />
            <input type="text" name="phone" id="phone" value="Phone" />
            <input
              type="text"
              name="county"
              id="country"
              onBlur={handleBlur}
              onFocus={handleFocus}
              value="Country"
            />
            <input
              type="text"
              name="Address"
              id="Address"
              onBlur={handleBlur}
              onFocus={handleFocus}
              value="Address"
            />
            <input type="text" name="city" id="city" value="City" />
            <div className="container">
              <label for="fileUpload" className="custom-file-upload">
                <span className="plus-sign"
                  ><img src={plus} alt=""
                /></span>
              </label>
              <input type="file" id="fileUpload" style={{display: 'none'}} />
              <span id="fileNamee" className="file-name">Upload Logo</span>
            </div>
          </div>
          <div className="right">
            <h4>Change password:</h4>
            <input
              type="text"
              name="nPassowrd"
              id="nPassowrd"
              onBlur={handleBlur}
              onFocus={handleFocus}
              value="New password"
            />
            <input
              type="text"
              name="nPassAgain"
              id="nPassAgain"
              onBlur={handleBlur}
              onFocus={handleFocus}
              value="new password (again)"
            />
          </div>
        </form>
        <button type="submit" className="saveBtn">{translate != null ? translate[43].value : 'Save'}</button>
  //  </div>    </div>*/}


      {/* <div className="header_container">
      <div
        className="container-fluid h-100 d-flex justify-content-between align-items-center px-4"
      >
        <button className="profile bg-transparent border-0">
          <img src={avatar} alt="" />
        </button>
   
        <div className="page_container">
          <div
            className="inner_header_container w-100 d-flex justify-content-between align-items-center"
          >
            <details className="custom-select light d-none d-lg-block">
              <summary className="radios rtl">
                <input
                  type="radio"
                  name="item"
                  id="default"
                  title="English"
                  checked
                />
                <input type="radio" name="item" id="עברית" title="עברית" />
                <input type="radio" name="item" id="English" title="English" />
              </summary>
              <ul className="list">
                <li>
                  <label
                    className="d-flex align-items-center justify-content-between w-100 rtl"
                    for="עברית"
                  >
                    <a
                      className="text-decoration-none text-white d-flex align-items-center justify-content-between w-100"
                      href="accountrtl"
                    >
                      עברית
                    </a>
                  </label>
                </li>
                <li>
                  <label
                    className="d-flex align-items-center justify-content-between w-100"
                    for="English"
                  >
                    <a
                      className="text-decoration-none text-white d-flex align-items-center justify-content-between w-100"
                      href="account"
                    >
                      English
                    </a>
                  </label>
                </li>
              </ul>
            </details>

            <div className="logo">
              <img src={logolight} alt="" />
            </div>
            <p className="mb-0 text-white f14 d-none d-lg-block">
              <a href="/books" className="text-decoration-none color10"> {translate!=null?translate[13].value:'BOOK LIST'} </a>
             
            </p>
            
          </div>
        </div>

        <a href='../ACCESSIBILITY.pdf' className="access bg-transparent border-0 d-none d-lg-block">
          <img src={accessibility} alt="" />
        </a>

        <div style={{'visibility':'hidden'}} onClick="sidebar('hamburger')" id="hamburger" className="access bg-transparent border-0 d-block d-lg-none">
          <img src={hamburger} alt="" />
        </div>
      </div>
    </div> */}

      {/* <div  className="sidebar_container px-2">
      <div
        className="container-fluid d-flex align-items-center h-100 justify-content-between"
      >
        <details className="custom-select light">
          <summary className="radios rtl">
            <input
              type="radio"
              name="item-2"
              id="default"
              title="English"
              checked
            />
            <input type="radio" name="item-2" id="עברית-2" title="עברית" />
            <input type="radio" name="item-2" id="English-2" title="English" />
          </summary>
          <ul className="list">
            <li>
              <label
                className="d-flex align-items-center justify-content-between w-100 rtl"
                for="עברית-2"
              >
                <a
                  className="text-decoration-none text-white d-flex align-items-center justify-content-between w-100"
                  href="account.html"
                >
                  עברית
                </a>
              </label>
            </li>
            <li>
              <label
                className="d-flex align-items-center justify-content-between w-100"
                for="English-2"
              >
                <a
                  className="text-decoration-none text-white d-flex align-items-center justify-content-between w-100"
                  href="account.en.html"
                >
                  English
                </a>
              </label>
            </li>
          </ul>
        </details>

        <p className="mb-0 text-white f14">
          <a href="" className="text-decoration-none color10"> {translate!=null?translate[13].value:'BOOK LIST'}  </a>

         
        </p>

        <button className="access bg-transparent border-0">
          <img src={accessibility} alt="" />
        </button>
      </div>
    </div> */}
      {/* 
    <div onClick="sidebar('')" className="page_container">
      <div className="container-fluid">
        <div className="search_container row gy-5 gy-lg-0">
          <img
            className="d-none d-lg-block back pointer" style={{ 'width': '70px', 'margin-left': '1%' }}
            src={back} onClick={() => Back()}
            alt=""
          />

          <div className="col-12 col-lg-6 mt-0">
            <div className="w-100 d-flex flex-column">
              <div className="d-flex flex-column gap-2">
                <h1 className="color1 f28 mb-0 fw700">{translate != null ? translate[1].value : 'Welcome'}  {firstName},</h1>
                <p className="f20 mb-0 fw400">
                  {translate != null ? translate[28].value : 'To start using READ-UP, please select a package:'}
                </p>
              </div>
            </div>
          </div>
          <div className="col-9 col-lg-6 mx-auto">
            <div className="row gy-4">
              <div className="col-sm-6">
                <div className="pay_card bs">
                  <p className="bs_val">{translate != null ? translate[32].value : 'Best value'} </p>

                  <div
                    className="d-flex flex-column justify-content-between align-items-center"
                  >
                    <div
                      className="d-flex flex-column justify-content-between align-items-center"
                    >
                      <h4 className="f22 color1 fw700">{translate != null ? translate[31].value : 'Yearly subscription'} </h4>
                      <h1 className="f28 mt-4 mb-0 color10">$33 / year</h1>
                      <p className="fw500 color1">Prepaid</p>
                    </div>

                    <div
                      className="d-flex flex-column justify-content-between align-items-center mt-4"
                    >
                      <p className="f18 color5 fw500">{translate != null ? translate[33].value : 'Unlimited reading'}</p>
                      <button onClick={() => purchasePopUp(12, 33)}
                        className="w-100 bg_color1 py-2 rounded-pill text-white"
                      >
                        Purchase
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="pay_card">
                  <div
                    className="d-flex flex-column justify-content-between align-items-center"
                  >
                    <div
                      className="d-flex flex-column justify-content-between align-items-center"
                    >
                      <h4 className="f22 color1 fw700">{translate != null ? translate[30].value : 'Monthly subscription'}</h4>
                      <h1 className="f28 mt-4 mb-0 color10">$3 / month</h1>
                      <p className="fw500 color1">Prepaid</p>
                    </div>

                    <div
                      className="d-flex flex-column justify-content-between align-items-center mt-4"
                    >
                      <p className="f18 color5 fw500">{translate != null ? translate[33].value : 'Unlimited reading'}</p>
                      <button onClick={() => purchasePopUp(1, 3)}
                        className="w-100 bg_color1 py-2 rounded-pill text-white"
                      >
                        Purchase
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}

      {/* <div onClick="sidebar('')" className="page_container pb-4 pb-md-0">
      <div className="search_container subscription_container py-4">
        <h1 className="color1 fw700 f30">{translate != null ? translate[34].value : 'Subscription details'}</h1>

        <div className="cal_details mt-4">
          <div
            className="inner_cal_details d-flex flex-column flex-md-row justify-content-between px-4 py-3 rounded-1"
          >
            <div className="d-flex align-items-center pb-2 pb-md-0">
              <div>
                <p className="color5 mb-0">Subscription ends:</p>
                <h5 className="mb-0 color1 fw500 f28">{subscribeLastDateString}</h5>
              </div>
              <div className="ms-5">
                <img src={cal} alt="" />
              </div>
            </div>
            <div className="line_ver"></div>
            <div className="d-flex align-items-center pt-2 pt-md-0 pb-2 pb-md-0">
              <div>
                <p className="mb-0 color5">Chapters read</p>
                <h5 className="mb-0 color1 fw500 f28">{chaptersCounter} Chapters</h5>
              </div>
              <div className="ms-5">
                <img src={book} alt="" />
              </div>
            </div>
            <div className="line_ver"></div>
            <div className="d-flex align-items-center pt-2 pt-md-0">
              <div>
                <p className="mb-0 color5">Total Reading Time:</p>
                <h5 className="mb-0 color1 fw500 f28">{readTimer} Hours</h5>
              </div>
              <div className="ms-5">
                <img src={timer} alt="" />
              </div>
            </div>
          </div> */}

      {/* <div className="form_sec row mt-5">
            <div className="col-12 col-sm-6">
              <p className="mb-2 color1 fw700 heebo f22">
                {translate != null ? translate[35].value : 'Editing your account information:'}
              </p>
              <input className="w-100" type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
              <input className="mt-3 w-100" type="text" placeholder="Last name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
              <input className="mt-3 w-100" type="text" placeholder="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} />
              <input className="mt-3 w-100" type="text" placeholder="Tel" value={tel} onChange={(e) => setTel(e.target.value)} />
              <div className="selectdiv">
                <select className="mt-3 w-100" value={gender} onChange={(e) => setGender(e.target.value)}>
                  <option value="Gender">Title</option>
                  <option value="Mr">Mr</option>
                  <option value="Ms">Ms</option>
                  <option value="Doctor">Doctor</option>
                  <option value="Porofessor">Porofessor</option>
                </select>
              </div>
              <input className="mt-3 w-100" type="text" placeholder="Country" onChange={(e) => setCountry(e.target.value)} />

              <input className="mt-3 w-100" type="text" placeholder="Address" onChange={(e) => setAddress(e.target.value)} />
              <input className="mt-3 w-100" type="text" placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} />

              <div className="selectdiv">
                <label className="w-100" >
                 
                </label>
              </div>
              <div className="date_cal position-relative">
                <input
                  className="mt-3 w-100"
                  type="text"
                  onFocus={(e) => focusBirthdate(e.target.value)}
                  placeholder="Birth date"
                  id="birthdate"
                />

              </div>
            </div> */}
      {/* <div className="col-12 col-sm-6 mt-5 mt-sm-0">
              <div className="d-flex flex-column justify-content-between h-100">
                <div>
                  <p className="mb-2 color1 fw700 heebo f22">{translate != null ? translate[36].value : 'Change password:'}</p>
                  <input className="w-100" type="text" placeholder="New password" value={password} onChange={(e) => setPassword(e.target.value)} />
                  <input
                    className="mt-3 w-100"
                    type="text"
                    placeholder="New password (again)" value={password2} onChange={(e) => setPassword2(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="save_btn text-end">
          <button className="bg_color1 px-5 py-2 rounded-pill border-0 text-white" onClick={() => Save()}>
            Save
          </button>
          <div>{msg}</div>
        </div>
      </div>
    </div>
 */}


      {/* <div id="footer">
      <a href="#" onClick={() => Contacts()}>Contact Us</a>
      <a href="#" onClick={() => Copyrights()}>Copy Rights</a>


    </div> */}

      <Footer lang={'en'} />
    </>)
}
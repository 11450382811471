import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types';
import axios from "axios";
import headerWhiteLogo from '../assets/img/headerWhiteLogo.svg';
import menuICon from "../assets/img/menuIcon.svg";
import crossMenu from '../assets/img/crossMenu.svg';
import whiteDropDown from '../assets/img/whiteDropDown.svg';
import questionMark from '../assets/img/questionMark.svg';
import accessWhite from '../assets/img/accessWhite.svg';
import crossPurchase from '../assets/img/crossPurchase.svg';
import cancel from '../assets/img/cancel.svg';
import addBookStepImg from '../assets/img/addBookStepImg.svg';
import selectDown from '../assets/img/selectDown.svg';
import upload1 from '../assets/img/upload 1.svg';
import redCross from '../assets/img/redCross.svg';
import dottedLine from '../assets/img/dottedLine.svg';
import numberUp from '../assets/img/numberUp.svg';
import numberDown from '../assets/img/numberDown.svg';
import dottedLineShort from '../assets/img/dottedLineShort.svg';
import successUploadImg from '../assets/img/successUploadImg.svg';
import { useLocation } from 'react-router-dom';

import '../assets/styles/nav/home.css';

function Nav({ nav }) {

  Nav.propTypes = {
    nav: PropTypes.string.isRequired,
  };
  console.log(nav);
  const role = localStorage.getItem('role');
  const orgid= localStorage.getItem('orgid');
  const location = useLocation();
  let [imageLogo, setImageLogo] = useState('');

  const navigate = useNavigate();
  const [translate, setTransate] = useState();
  const [generupload, setGenereUpload] = useState('');
  const [PaymentM, setPaymentM] = useState('');
  const [PaymentY, setPaymentY] = useState('');
  const [geners, setGeners] = useState([]);
  const [showloader, setshowloader] = useState()
  const [file, setFile] = useState()
  const [filename, setFilename] = useState('');
  let [chapterList, setChapterList] = useState([1])
  const [chaptersfile, setChaptersFile] = useState([])
  const [filechaptersname, setFileChaptersname] = useState([]);
  const [msg, setMsg] = useState('');
  const [finish, setFinish] = useState(false)
  const [text, setText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [Libraries, setLibrary] = useState([])
  const headerClass = `header ${role === 'Admin' || role === 'Organization Manager' ? 'Admin' : ''}`;
  let [period, setPeriod] = useState('Month');
  let [numUsers, setNumUsers] = useState(0);
  const [total, setTotal] = useState('');



  const [loading, setLoading] = useState(false); // Add loading state

  const localStorageUserId = parseInt(localStorage.getItem('userId'), 10);

  const toggleActive = (buttonId) => {
    const button1 = document.getElementById('button1');
    const button2 = document.getElementById('button2');
    if (button1 != null) {
      button1.classList.remove('active');
    }
    if (button2 != null) {
      button2.classList.remove('active');
    }
    const activeButton = document.getElementById(buttonId);
    if (activeButton != null) {
      activeButton.classList.add('active');
    }
  };

  const openModal = () => {
    const mobileMenuTop = document.getElementById("mobileMenuTop");
    const modal = document.getElementById("myModal");
    resetModal();
    if (mobileMenuTop != null) {
      mobileMenuTop.style.left = "-100%";
    }
    if (modal != null) {
      modal.style.display = "block";
    }
  };

  const openPaymentModal = () => {
    const paymentModal = document.getElementById("paymentModal");
    const mobileMenuTop = document.getElementById("mobileMenuTop");

    if (mobileMenuTop != null && paymentModal != null) {
      mobileMenuTop.style.left = "-100%";
      paymentModal.style.display = "block";
    }
  };

  const closePaymentModal = () => {
    const paymentModal = document.getElementById("paymentModal");
    if (paymentModal != null) {
      paymentModal.style.display = "none";
    }
  };
  const onChangeHandler = (event) => {
    console.log(event.target.files[0]);
    setFile(event.target.files[0])
    setFilename(event.target.files[0].name);
  }
  const HandlerChap = (index) => {
    const newChapterList = chaptersfile.filter((_, i) => i !== index);
    const newFileChaptersName = fileChaptersName.filter((_, i) => i !== index);
    const newVisibleChapters = visibleChapters.filter((_, i) => i !== index);
    setChaptersFile(newChapterList);
    setChapterList(newChapterList)
    setFileChaptersName(newFileChaptersName);
    setVisibleChapters(newVisibleChapters);
};

  const onChangeHandlerChap = (event,index) => {
    //debugger;
    if (document.getElementsByClassName('chaptername').length > 0 && event.target != undefined && event.target.files[0] != undefined) {

      const files = [...fileChaptersName];
      files[index] = event.target.files[0].name;
      setFileChaptersName(files);

      chaptersfile.push(event.target.files[0]);
      let tmp = [...chaptersfile];
      setChaptersFile(tmp)



      // console.log(event.target.files[0]);
      // chaptersfile.push(event.target.files[0]);
      // let tmp = [...chaptersfile];
      // setChaptersFile(tmp)

      // filechaptersname.push(event.target.files[0].name);
      // let tmpchapters = [...filechaptersname];
      // setFileChaptersname(tmpchapters);

    }

  }

  const toggleDropdown = () => {
    const dropdown = document.getElementById("dropdown");
    if (dropdown != null) {
      dropdown.classList.toggle("show");
    }
  };
  const closeMobileMenu = () => {
    const mobileMenuTop = document.getElementById("mobileMenuTop");
    if (mobileMenuTop != null) {
      mobileMenuTop.style.left = "-100%";
    }
  };
  const openMobileMenu = () => {
    const mobileMenuTop = document.getElementById("mobileMenuTop");
    if (mobileMenuTop != null) {
      mobileMenuTop.style.left = "0%";
    }
  };

  const closeModal = () => {
    const modal = document.getElementById("myModal");
    if (modal != null) {
      modal.style.display = "none";
    }
  };

  const nextStep = (step) => {
    const modal = document.getElementById("myModal");
    const modalContent = modal ? modal.getElementsByClassName("modal-content") : [];
    if (modalContent.length > step) {
      modalContent[step - 1].style.display = "none";
      modalContent[step].style.display = "block";
    }
  };

  const prevStep = (step) => {
    const modal = document.getElementById("myModal");
    const modalContent = modal ? modal.getElementsByClassName("modal-content") : [];
    if (step > 1 && modalContent.length >= step) {
      modalContent[step - 1].style.display = "none";
      modalContent[step - 2].style.display = "block";
    }
  };

  const showFileName = (input) => {
    const fileName = document.getElementById("fileName");
    if (fileName != null) {
      fileName.textContent = input.files.length > 0 ? input.files[0].name : "No file chosen";
    }
  };

  const firstStep = () => {
    setFinish(false);
    setMsg('')
    resetModal();
  };

  const restartModal = () => {
    const modal = document.getElementById("myModal");
    if (modal != null) {
      modal.style.display = "none";
    }
  };

  const resetModal = () => {
    const modal = document.getElementById("myModal");
    const modalContent = modal ? modal.getElementsByClassName("modal-content") : [];
    if (modalContent.length > 0) {
      Array.from(modalContent).forEach(content => content.style.display = "none");
      modalContent[0].style.display = "block";
    }
  };
  const Logout = () => {
    localStorage.removeItem('loginDate')
    navigate('/');
  }
  const validateForm = (step) => {
    const title = document.getElementById('title');
    const library = document.getElementById('library');
    const author = document.getElementById('author');
    const language = document.getElementById('language');
    const style = document.getElementById('style');
    const voice = document.getElementById('voice');
    const description = document.getElementById('description');
    const file =document.getElementById('uploadInput')
    // Reset error borders

    title.classList.remove('error-border');
    library.classList.remove('error-border');
    author.classList.remove('error-border');
    language.classList.remove('error-border');
    style.classList.remove('error-border');
    voice.classList.remove('error-border');
    description.classList.remove('error-border');

    let isValid = true;

    if (step === 2) {
      if (!title.value) {
        title.classList.add('error-border');
        isValid = false;
      }
      if (library.value === '0') {
        library.classList.add('error-border');
        isValid = false;
      }
      if (!author.value) {
        author.classList.add('error-border');
        isValid = false;
      }
      if (language.value === ' ') {
        language.classList.add('error-border');
        isValid = false;
      }
      if (style.value === '0') {
        style.classList.add('error-border');
        isValid = false;
      }
      if (voice.value === '0') {
        voice.classList.add('error-border');
        isValid = false;
      }
      if (!description.value) {
        description.classList.add('error-border');
        isValid = false;
      }
      if(!file.value){
        file.classList.add('error-border');
        isValid = false;
      }
    } else if (step === 3) {
      if (file === null) {

        isValid = false;
      }
      if (chaptersfile.length === 0) {

        isValid = false;
      }
    }

    if (!isValid) {
      setErrorMessage('Mandatory fields please fill in.');
    } else {
      setErrorMessage('');
    }

    return isValid;
  };
  const [fileChaptersName, setFileChaptersName] = useState([]);
  const [visibleChapters, setVisibleChapters] = useState([]);

  const AddChapterList = () => {
      const newChapterList = [...chapterList, chapterList.length + 1];
      const newVisibleChapters = [...visibleChapters, true]; // Add visibility state for the new chapter
      setChapterList(newChapterList);
      setVisibleChapters(newVisibleChapters);
  };


  // const AddChapterList = () => {
  //   //debugger;
  //   chapterList.push(1);
  //   let tmp = [...chapterList];
  //   setChapterList(tmp)


  // }
  const handleContinue = (e, step) => {
    e.preventDefault();
    if (validateForm(step)) {
      nextStep(step);
    }
  };

  const handleSubmit = async () => {

    try {
      const bookname = document.getElementById('modal-body2');
      const chapters = document.getElementsByClassName('chaptername');
      const formData = new FormData();
      let chapterStr = '';

      for (let i = 0; i < fileChaptersName.length; i++) {
        chapterStr += chapters[i].value + ';';
      }

      formData.append('bookName', document.getElementById('title').value);
      formData.append('chaptersNames', chapterStr);
      formData.append('bookFile', file); // Ensure 'file' is defined
      formData.append('userId', localStorage.userId);
      formData.append('library', document.getElementById('library').value);
      formData.append('author', document.getElementById('author').value);
      formData.append('language', document.getElementById('language').value);
      formData.append('style', document.getElementById('style').value);
      formData.append('voice', document.getElementById('voice').value);
      formData.append('description', document.getElementById('description').value);
      formData.append('onlyChapters', false)
      chaptersfile.forEach((file, i) => formData.append(`chaptersFiles`, file)); // Ensure 'chaptersfile' is defined

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      setLoading(true);
      setMsg('Now we process the book  Processing will be done in a few minutes, after which the book will be available for reading.');

      setshowloader(true);

       const url = `https://api.readupp.com/api/Books/UploadFiles`;

    //  const url = `https://localhost:44318/api/Books/UploadFiles`;

      const response = await axios.post(url, formData, config);
      // Start loading spinner
      if (response.status === 200) {
        setshowloader(false);
        setMsg(' Book successfully added !');
        setFinish(true);
        document.getElementById('author').value = '';
        document.getElementById('title').value = '';
        document.getElementById('description').value = '';
        document.getElementById('language').value = 0;
        document.getElementById('style').value = 0;
        document.getElementById('voice').value = 0;
        document.getElementById('library').value = 0;
        // document.getElementById('file').value = 0;
        setFilename('');
        setFile(undefined);
        chaptersfile.length = 0;
        //setChaptersFile([]);


      } else {
        setMsg('An unexpected error occurred.');
      }
    } catch (error) {
      setshowloader(false);

      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        if (error.response.status === 500) {
          setMsg('Server error: 500 Internal Server Error.');
        } else {
          setMsg(`Server error: ${error.response.status}`);
        }
      } else if (error.request) {
        // The request was made but no response was received
        setMsg('No response received from server.');
      } else {
        // Something happened in setting up the request that triggered an Error
        setMsg('Error: ' + error.message);
      }
    }

    finally {
      setLoading(false); // Stop loading spinner
    }

  }

  const purchasePopUp2 = (months, price) => {
    closePaymentModal();
    //debugger;
    document.getElementById('exampleModal').style.display = 'block';
    document.getElementById('exampleModal').style.opacity = '1';

    document.getElementById('tranzila').setAttribute('src', 'https://direct.tranzila.com/vayikra22/iframenew.php?sum=' + price + '&currency=2&email=' + localStorage.email + '&fail_url_address=https://direct.tranzila.com/vayikra22/iframenew.php&success_url_address=http://104.238.214.197:8087/success');

    localStorage.monthreq = months;
  }
  const purchasePopUp = () => {
    //debugger;
    let price = total
    let months = 1
    if (period == "Year") {
      months = 12;
    }
    closePaymentModal();
    document.getElementById('exampleModal').style.display = 'block';
    document.getElementById('exampleModal').style.opacity = '1';


    document.getElementById('tranzila').setAttribute('src', 'https://direct.tranzila.com/vayikra22/iframenew.php?sum=' + price + '&currency=2&email=' + localStorage.email + '&fail_url_address=https://direct.tranzila.com/vayikra22/iframenew.php&success_url_address=http://104.238.214.197:8087/success');

    localStorage.monthreq = months;
  }

  const closePurchase = () => {
    document.getElementById('exampleModal').style.display = 'none';
    document.getElementById('exampleModal').style.opacity = '0';

  }
 



  const changePeriod = (value) => {

    
    setPeriod(value)
    if (value == "Month") {
      const parsedUsers = parseInt(numUsers);
  
    for (let group of PaymentM) {
      // Extract the upper limit from the key
      const upperLimit = parseInt(group.eng_key.split('-')[1]);
  
      if (parsedUsers < upperLimit) {
        // Use the first value before the semicolon
        const value = parseFloat(group.eng_value.split(';')[0]);
        setTotal(parsedUsers * value);
        break; // Exit the loop once a condition is satisfied
      }
    }
    }
    else {
      const parsedUsers = parseInt(numUsers);
  
      for (let group of PaymentM) {
        // Extract the upper limit from the key
        const upperLimit = parseInt(group.eng_key.split('-')[1]);
    
        if (parsedUsers < upperLimit) {
          // Use the first value before the semicolon
          const value = parseFloat(group.eng_value.split(';')[0]);
          setTotal(parsedUsers * value * 12);
          break; // Exit the loop once a condition is satisfied
        }
      }
    }
  }
  // const upDown= ()=> {
  //   const numberInput = document.getElementById("customNumberInput");
  //   const decreaseButton = document.querySelector(".decrease");
  //   const increaseButton = document.querySelector(".increase");

  //   decreaseButton.addEventListener("click", function () {
  //     numberInput.stepDown();
  //   });

  //   increaseButton.addEventListener("click", function () {
  //     numberInput.stepUp();
  //   });
    
  // }


  const upDown = (isIncrease) => {
    let newValue = isIncrease ? numUsers + 1 : Math.max(numUsers - 1, 0);
    changeNumUsers(newValue);
  };



  const changeNumUsers = (value) => {
    setNumUsers(value)
    if (period == "Month") {
      const parsedUsers = parseInt(value);
  
    for (let group of PaymentM) {
      // Extract the upper limit from the key
      const upperLimit = parseInt(group.eng_key.split('-')[1]);
  
      if (parsedUsers < upperLimit) {
        // Use the first value before the semicolon parseFloat()
        const value =group.eng_value.split(';')[0];
        setTotal(parsedUsers * value);
        break; // Exit the loop once a condition is satisfied
      }
    }
    }
    else {
      const parsedUsers = parseInt(value);
  
      for (let group of PaymentM) {
        // Extract the upper limit from the key
        const upperLimit = parseInt(group.eng_key.split('-')[1]);
    
        if (parsedUsers < upperLimit) {
          // Use the first value before the semicolon
          parseFloat()
          const value = group.eng_value.split(';')[0];
          setTotal(parsedUsers * value * 12);
          break; // Exit the loop once a condition is satisfied
        }
      }
    }


  }

  useEffect(() => {

    fetch('https://api.readupp.com/api/UserSettings/GetReadupEn')
      .then(async response => {
        const data = await response.json();
        // debugger;
        setTransate(data);
        console.log(data, "data", translate, "translate ")
      })
      .catch(error => {

      });
      fetch('https://api.readupp.com/Login/GetOrg?userid=' + localStorage.userId + '&orgid=-1')
      .then(async response => {
        //debugger;
        const data = await response.json();
        if(data.logo){
           setImageLogo("https://vayikra-bible.com/" + data.logo)
        }
       else{
        setImageLogo('')
       }
        
      })
      .catch(error => {

        console.error('There was an error!', error);
      });
    if (role === 'Admin' || role === 'Organization Manager') {

      fetch(`https://api.readupp.com/Login/GetOrganizationPayment?organizaitonid=${localStorage.orgid}`)
   //   fetch(`https://localhost:44318/Login/GetOrganizationPayment?organizaitonid=${localStorage.orgid}`)
        .then(async response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setPaymentM(data);

        })
        .catch(error => {
          console.error('There was an error fetching the payment data:', error);
        });
    }
    else {
      fetch(`https://api.readupp.com/Login/GetUserPayment?userid=${localStorageUserId}`)
      //fetch(`https://localhost:44318/Login/GetUserPayment?userid=${localStorageUserId}`)
        .then(async response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          let month = data.filter(x => x.eng_key === 'Monthly');
          let year = data.filter(x => x.eng_key === 'Yearly');
          const monthlyValue = month.length > 0 ? month[0].eng_value.split(';')[0] : '';
          const yearlyValue = year.length > 0 ? year[0].eng_value.split(';')[0] : '';
          
          // Update state with the extracted values
          setPaymentM({ ...month[0], eng_value: monthlyValue });
          setPaymentY({ ...year[0], eng_value: yearlyValue });

          // setPaymentM(month[0]);
          // setPaymentY(year[0]);
          console.log('Monthly Payment:', PaymentM);
          console.log('Yearly Payment:', PaymentY);
        })
        .catch(error => {
          console.error('There was an error fetching the payment data:', error);
        });
    }

    fetch('https://api.readupp.com/getAllLibrarys?Id=' + localStorage.userId)
      //  fetch('https://localhost:44318/getAllLibrarys?Id=' + localStorage.userId)
      .then(async response => {
        const data = await response.json();
        // setLibrary(data);

        if (Array.isArray(data)) {
          if (role !== 'Admin' && role !== 'Organization Manager' && role !== "learning central manager") {
            const libraryFilter = data.filter(library => {
              const libraryUserId = parseInt(library.userId, 10);
              console.log('Library userId:', libraryUserId, 'LocalStorage userId:', localStorageUserId);
              return libraryUserId === localStorageUserId && library.visibility === 1;
            });
            console.log('Filtered libraries:', libraryFilter);
            setLibrary(libraryFilter);
          }
          else {
            setLibrary
              (data)
          }
        }
      })
      .catch(error => {
        console.error('There was an error fetching user settings!', error);
      });



    fetch('https://api.readupp.com/api/Genre/GetGenres')
      .then(async response => {
        const data = await response.json();
        setGeners(data);
        localStorage.loginDate = Date.now()
      })
      .catch(error => {
        this.setState({ errorMessage: error.toString() });
        console.error('There was an error!', error);
      });

    // setChapterList(chapterList)
    // setshowloader(false);
    // This ensures all event listeners and DOM manipulation occurs only once after component mount
  }, []);
  return (
    <div dir='ltr'>
      <div className="mobileMenuTop" id="mobileMenuTop">
        <div className="inner">
          <div className="headerText">
            <img className="logo" onClick={closeMobileMenu} src={crossMenu} alt="" />
            <div className="switchBtn">
              <a href="#" id="button1" onClick={() => toggleActive('button1')} className="eng active">Eng</a>
              <a href={nav} id="button2" onClick={() => toggleActive('button2')} className="hebrew">עברית</a>
            </div>
          </div>
          <ul>
            {(role === 'Admin' || role === 'Organization Manager' || role === "learning central manager") && (
              <li><a className={location.pathname === '/Users' ? 'nav-link' : ' '} href="./Users">{translate != null ? translate[107].value : 'Users'}</a></li>)}
            {(role === 'Admin' || role === 'Organization Manager' || role == 'learning central manager') && (
              <li><a className={location.pathname === '/Library' ? 'nav-link' : ' '} href="./Library">{translate != null ? translate[108].value : 'Libraries'}</a></li>)}


            <li><a className={location.pathname === '/books' ? 'nav-link' : ' '} href="./books">{translate != null ? translate[111].value : ' Books'}</a></li>
            <li><a href="#" onClick={openModal}>{translate != null ? translate[110].value : 'Upload a Book'} </a></li>
            <li><a className={location.pathname === '/account' ? 'nav-link' : ' '} href="./account">{translate != null ? translate[109].value : 'My Account'}</a></li>
            <li><a onClick={() => Logout()}>{translate != null ? translate[12].value : 'Log Out'} </a></li>
            {(role === 'learning central manager' && ( <li><a href="#" style={{display:'none'}} >{translate != null ? translate[112].value : 'Upgrade'}</a></li>))}

            {(role !== 'learning central manager' && (<li className="upgrade"><a href="#" onClick={openPaymentModal}>{translate != null ? translate[112].value : 'Upgrade'}</a></li>))}
          </ul>
          <ul>
            <li><a href="#">{translate != null ? translate[114].value : 'Accessibility'}</a></li>
            <li><a href="#">{translate != null ? translate[115].value : 'Support'}</a></li>
            <li><a href="#">{translate != null ? translate[116].value : 'Terms of Use'}</a></li>
            <li><a href="#">{translate != null ? translate[117].value : 'Privacy Policy'}</a></li>
          </ul>
        </div>
      </div>
      {/* className={role === 'Admin' || role === 'Organization Manager' ? 'Admin':'header' */}
      <div className={headerClass} >
        <div className="left">
          <img src={menuICon} className="mobileMenu" onClick={openMobileMenu} alt="" />
          <a href="./books" className="logo">
            <img src={headerWhiteLogo} alt="" />
          </a>
          <div className="overLay">
            <ul style={{ marginTop: '70px;' }} >

              {(role === 'Admin' || role === 'Organization Manager' || role == 'learning central manager') && (
                <li><a className={location.pathname === '/Users' ? 'nav-link' : ' '} href="./Users">{translate != null ? translate[107].value : 'Users'}</a></li>)}

              {(role === 'Admin' || role === 'Organization Manager' || role == 'learning central manager') && (
                <li><a className={location.pathname === '/Library' ? 'nav-link' : ' '} href="./Library">{translate != null ? translate[108].value : 'Libraries'}</a></li>)}


              <li><a className={location.pathname === '/books' ? 'nav-link' : ' '} href="./books">{translate != null ? translate[111].value : ' Books'}</a></li>
              <li><a href="#" onClick={openModal}>{translate != null ? translate[110].value : 'Upload a Book'}</a></li>
              <li><a className={location.pathname === '/account' ? 'nav-link' : ' '} href="./account">{translate != null ? translate[109].value : 'My Account'}</a></li>
              <li><a onClick={() => Logout()}>{translate != null ? translate[12].value : 'Log Out'} </a></li>
              {(((orgid != 1 && (role !== 'Organization Manager' && role !== 'Admin')) )&& ( <li><a href="#"  style={{display:'none'}}>{ translate != null ? translate[112].value : 'Upgrade'}</a></li>))}
              {((orgid == 1 || role === 'Organization Manager' || role === 'Admin') && ( <li><a href="#" onClick={openPaymentModal} >{ translate != null ? translate[112].value : 'Upgrade'}</a></li>))}

            </ul>
          </div>
        </div>
        <div className="right">
          <div className="lang" id="myDropdown" onClick={toggleDropdown}>
            <p>English</p>
            <img src={whiteDropDown} alt="" />
            <div className="dropdown" id="dropdown">
              <a href={nav}>Hebrew</a>
              {/*  */}
            </div>
          </div>
          <a className="icons" href="#"><img src={accessWhite} alt="" /></a>
          <a className="icons" href="help"><img src={questionMark} alt="" /></a>
          {(role === 'Admin' || role === 'Organization Manager' || role === "learning central manager") && (
            (imageLogo !='' &&    <div className="logoInst">
                  <img className="instituteLogo" src={imageLogo} alt="Institute Logo" />
                </div>)
          )}
        </div>
      </div>
      {/* <div className="mainContent bookMobileColor"> */}



      {/* Payment Modal */}
      {(role === 'Admin' || role === 'Organization Manager') && (
        <div className="paymentModal" id="paymentModal">
          <div className="innerContent">
            <img
              onClick={closePaymentModal}
              className="crossPurchase"
              src={crossPurchase}
              alt=""
            />
            <h1>Purchase a subscription to READUP for your trainees</h1>
            <p>
              A subscription allows your users access to all the books included in
              the Readup library and in addition allows you and the users to upload
              books independently
            </p>

            <div className="userCards">

              {Array.isArray(PaymentM) && PaymentM.map(payment => {
                const [firstValue] = payment.eng_value.split(';'); // Get the first number before ';'
                const [firstkey] = payment.eng_key.split(';');
                return (
                  <div className="card" key={payment.id}>
                    <p>{firstkey} Users</p>
                    <h6>{firstValue} $/User</h6> {/* Display only the first value */}
                    <img src={dottedLine} alt="" />
                  </div>
                );
              })}
              {/* <div className="card">
                <p>100-500 Users</p>
                <h6>6 NIS/User</h6>
                <img src={dottedLine} alt="" />
              </div>
              <div className="card">
                <p>500-1000 Users</p>
                <h6>5 NIS/User</h6>
                <img src={dottedLine} alt="" />
              </div>
              <div className="card">
                <p>1000+ Users</p>
                <h6>Contact Us</h6>
              </div> */}
            </div>
            <form action="#">
              <div className="left">
                <p>Number of users:</p>
                <div className="number-input-container">
                  <input id="customNumberInput" min="0" type="number" placeholder="" value={numUsers} onChange={(e) => changeNumUsers(e.target.value)} />
                  {/* <input type="number" min="0" id="customNumberInput" value="0"> */}
                  {/* <input type="number" min="0" id="customNumberInput" defaultValue="0" /> */}
                  <div className="custom-buttons">
                    <button type="button" onClick={() => upDown(true)} className="increase">
                      <img src={numberUp} alt="" />
                    </button>
                    <button type="button"  onClick={() => upDown(false)} className="decrease">
                      <img src={numberDown} alt="" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="right">
                <p>For:</p>
                <div className="custom-select">
                  <select value={period} name="period" id="period" onChange={(e) => changePeriod(e.target.value)}>
                    <option value="Month">Month</option>
                    <option value="Year">Year</option>
                  </select>
                  <div className="select-icon">
                    <img src={selectDown} alt="" />
                  </div>
                </div>
              </div>
            </form>

            <div className="total">
              <div className="leftt">
                <h6 style={{ marginRight: '31px' }}>Summary</h6>
                <h3 className="firstH3">{numUsers} Users</h3>
                <img style={{ marginRight: '35px' }} src={dottedLineShort} alt="" />
                <h6>For {period} </h6>
              </div>
              <div className="rightt">
                <h3>Total: {total} $</h3>
              </div>
            </div>
            <button className="purchaseBtn" onClick={() => purchasePopUp()}>Purchase</button>
          </div>
        </div>)}
      {(role != 'Admin' || role != 'Organization Manager') && (
        <div className="paymentModal" id="paymentModal">
          <div className="innerContent">
            <img
              onClick={closePaymentModal}
              className="crossPurchase"
              src={crossPurchase}
              alt=""
            />
            <h1>You must upgrade to perform this action</h1>
            <p>
              A subscription allows you free practice in all the books included in
              the Readup library and in addition uploading your own books
            </p>
            <div className="twoOffer">
              <div className="monthly">
                <h3>
                  {PaymentM.eng_key} <br />
                  Subscription
                </h3>


                <h5>{PaymentM.eng_value}$ / {PaymentM.eng_key}</h5>
                <p style={{ marginBottom: '23px' }}>Automatically <br />renews</p>
                <p style={{ marginBottom: '19px' }}>Unlimited reading</p>
                <div className="purchaseBtn">
                  <button onClick={() => purchasePopUp2(PaymentM.eng_value, PaymentM.eng_key)}>Purchase</button>
                </div>
              </div>
              <div className="yearly">
                <div className="bestValue">
                  <p>Best Value</p>
                </div>
                <h3>
                  {PaymentY.eng_key} <br />
                  Subscription
                </h3>
                <h5 style={{ marginBottom: '19px' }}> {PaymentY.eng_value}$ / {PaymentY.eng_key}</h5>
                <p style={{ marginBottom: '39px' }}>Prepaid</p>
                <p style={{ marginBottom: '15px' }}>Unlimited reading</p>
                <div className="purchaseBtn">
                  <button onClick={() => purchasePopUp2(PaymentY.eng_value, PaymentY.eng_key)}>Purchase</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )

      }

      {/* </div>  */}


      {/* add book */}
      <div id="myModal" className="addBookModal">
        {/* Modal Content (Step 1) */}
        <div className="modal-content">
          {/* Your modal content */}
          <div className="modal-header">
            <span className="close" onClick={closeModal}>
              <img src={cancel} alt="" />
            </span>
            <h2>{translate != null ? translate[51].value : 'Add your text'}</h2>
          </div>
          <div className="modal-body">
            <p style={{ marginBottom: '20px' }}>
              You can manually add any book or text file you want, for an automatic narrator to read it to you. There is no limit for the type of book or text you can upload.
            </p>
            <p>
              To start upload, it is important that you have the text chapters in one of the following formats:
            </p>
            <p style={{ paddingLeft: '10px' }}>• You can upload a .doc text file.</p>
            <p style={{ paddingLeft: '10px' }}>
              • If you do not have a text file, you can scan the book, and upload it as a JPG or PDF
            </p>
            <img src={addBookStepImg} alt="" />
            <button onClick={() => nextStep(1)} className="step1Btn">{translate != null ? translate[10].value : ' Continue  '} </button>
          </div>
        </div>
        {/* Modal Content (Step 2) */}
        <div className="modal-content" id="step2" style={{ display: 'none' }}>
          <div className="modal-header">
            <span className="close" onClick={closeModal}>
              <img src={cancel} alt="" />
            </span>
            <h2>{translate != null ? translate[51].value : 'Add your text'}</h2>
          </div>
          <div className="modal-body">
            <h2 className="headerTitle">1 | Book details</h2>
            <p className="headerPara">
              First, fill in the book details, choose the library to which you want to upload the book, and choose the type of narration:
            </p>
            <form action="#">
              <div className="row">
                <input type="text" name="title" id="title" placeholder={translate != null ? translate[158].value : ' Title  '} />
                <div className="custom-select"  >

                  <select class="input__field" required name="library" id="library" onChange={(e) => setGenereUpload(e.target.value)}>
                    <option value="0">Choose a library  </option>
                    {Libraries.map(g => {
                      return (
                        <option disabled={g.id === 1 && g.userId !== localStorageUserId} value={g.id}>{g.libraryName}</option>
                      )
                    })}
                  </select>
                  <div className="select-icon">
                    <img src={selectDown} alt="" />
                  </div>
                </div>
              </div>

              <div className="row">
                <input type="text" name="author" id="author" placeholder={translate != null ? translate[79].value : ' Author   '} />
                <div className="custom-select">
                  <select class="input__field" required id="language">
                    <option value=" ">{translate != null ? translate[55].value : 'Language'}</option>
                    <option value="2">English</option>
                    <option value="1">עברית</option>
                  </select>
                  <div className="select-icon">
                    <img src={selectDown} alt="" />
                  </div>
                </div>
              </div>

              <div className="row">

                <div className="custom-select">
                  <select class="input__field" required name="" id="style" onChange={(e) => setGenereUpload(e.target.value)}>
                    <option value="0">Genere</option>
                    {geners.map(g => {
                      return (
                        <option value={g.id}>{g.value}</option>
                      )
                    })}
                  </select>
                  <div className="select-icon">
                    <img src={selectDown} alt="" />
                  </div>
                </div>

                <div className="custom-select">
                  <select class="input__field" id="voice" required>
                    <option value="0"  >{translate != null ? translate[68].value : 'Narrator voice  '}</option>
                    <option value="Male" selected>Male</option>
                    <option value="Female">Female</option>
                  </select>
                  <div className="select-icon">
                    <img src={selectDown} alt="" />
                  </div>
                </div>
              </div>

              <textarea name="description" id="description" placeholder="Book description (will appear as a summary next to the book name)"></textarea>
              <div className="custom-upload">
                <span className="file-name" style={{marginRight:'10px'}} onChange={(e) => showFileName(e.target)} id="fileName">
                  {finish ? text : ''}
                      
                  {filename != '' ? filename : translate && translate[77] ? translate[77].value : 'Upload picture'}
                </span>

                <button>
                  <input type="file" id="uploadInput" onChange={(e) => onChangeHandler(e)}  required/>
                  <img src={upload1} alt="" />Select File
                </button>
              </div>
              <div className="modal-footer">
                {/* <button onClick={() => prevStep(2)} className="back">{translate != null ? translate[104].value : ' Back  '}</button> */}
                <button onClick={(e) => handleContinue(e, 2)} className="continueBtn">{translate != null ? translate[10].value : ' Continue  '}</button>
              </div>
              {errorMessage && <p style={{ color: 'red' }} className="error-message">{errorMessage}</p>}
            </form>
          </div>
        </div>
        {/* Modal Content (Step 3) */}
        <div className="modal-content" id="step3" style={{ display: 'none' }}>
          <div className="modal-header">
            <span className="close" onClick={closeModal}>
              <img src={cancel} alt="" />
            </span>
            <h2>{translate != null ? translate[51].value : 'Add your text'}</h2>
          </div>
          <div className="modal-body">
            <h2 className="headerTitle">2 | Upload chapters</h2>
            <p className="headerPara">
              Now upload the chapters you’ve scanned: <br />
              You can upload PDF, JPG or DOC files
            </p>
            {chapterList.map((item, index) => {
              return (

// style={{ display: visibleChapters[index] ? 'block' : 'none' }}
                <div className="fileUploadCancel2" >
                  <div className="custom-upload2">
                    <span style={{marginRight:'12px'}} className="file-name" id="fileName"> Chapter {index + 1} </span>
                    {/* <div class="file-input"> */}
                    <input type="file" class="form-control chaptername" required accept=".jpg, .jpeg, .png,.txt,.bmp,.doc,.docx,.pdf" onChange={(e)=>onChangeHandlerChap(e ,index)} />
                    <button style={{ marginright2: '18px' }}>
                      <img src={upload1} alt="" required />Select File
                    </button>
                    <div>{fileChaptersName[index]}</div>
                    {/* <p>{translate!=null?translate[71].value:'Upload file'}</p> */}
                    {/* </div> */}
                  
                  </div>
                    <img className="redCross2"  onClick={() => HandlerChap(index)}  style={{ margin: '7px' }} src={redCross} alt="" />
                </div>
              )
            })}
            <p className="addPara" onClick={() => AddChapterList()}>{translate != null ? translate[70].value : '+ Add chapter'} +</p>
            <div className="modal-footer">
              <button onClick={() => prevStep(3)} className="back">{translate != null ? translate[104].value : ' Back  '}</button>
              <button onClick={(e) => handleContinue(e, 3)} className="continueBtn">{translate != null ? translate[10].value : ' Continue  '}</button>
            </div>
            {errorMessage && <p style={{ color: 'red' }} className="error-message">{errorMessage}</p>}
          </div>
        </div>
        {/* Modal Content (Step 4) */}
        <div className="modal-content" id="step4" style={{ display: 'none' }}>
          <div className="modal-header">
            <span className="close" onClick={closeModal}>
              <img src={cancel} alt="" />
            </span>
            <h2>{translate != null ? translate[51].value : 'Add your text'}</h2>
          </div>
          <div className="modal-body">
            <h2 className="headerTitle">  {finish ? msg : ' '} </h2>
            <p className="headerPara">
              {!finish ? msg : ''}
            </p>
            <img className="successUpload" src={successUploadImg} alt="" />
            <div className="modal-footer">
              <button onClick={firstStep} style={{ width: '200px' }} className="back">
                {translate != null ? translate[106].value : ' Upload another book  '}
              </button>
              <button onClick={() => handleSubmit()} className="continueBtn"> {translate != null ? translate[105].value : ' Finish '}</button>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}


      <div style={{ display: 'none' }} class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog subs_modal">
          <div class="modal-content" style={{ 'margin-top': '20%' }}>
            <div class="container-fluid rtl" >

              <div class="w-100">

                <iframe id="tranzila" src="https://direct.tranzila.com/vayikra22/iframenew.php?sum=1&fail_url_address=http://google.com&notify_url_address=http://google.com">
                </iframe>

                <button class="px-4 py-2 text-white bg_color1 rounded-3 border-0 mt-5" id="closeiframe" onClick={() => closePurchase()}>
                  Close
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>





    </div>
  );
}

export default Nav;

import React, { useContext, useState, useEffect } from "react";

import { useNavigate, useLocation } from 'react-router-dom';
import hamburger from '../assets/icons/hamburger.svg'
import logolight from '../assets/images/logo-light.png'
import avatar from '../assets/icons/avatar.svg'
import search from '../assets/icons/search.svg'
import accessibility from '../assets/icons/accessibility-light.svg'
import back from '../assets/icons/back.svg'
import cal from '../assets/icons/cal.svg'
import book from '../assets/icons/book.svg'
import timer from '../assets/icons/timer.svg'

import needHelp from "../assets/images/needHelp.svg"
import backArrow from "../assets/img/backArrow.svg";
import calander from "../assets/img/calander.svg";
import myAccount1 from "../assets/img/myAccount1.svg";
import myAccount2 from "../assets/img/myAccount2.svg";
import myAccount3 from "../assets/img/myAccount3.svg";
import circleTick from "../assets/img/circleTick.png";
import circleTickEmpty from "../assets/img/circleTickEmpty.png";
import menuCross from "../assets/images/menuCross.svg"
import menuICon from "../assets/images/menuICon.png"
import icons8down48 from "../assets/images/icons8-down-48.png"
import Navrtl from "./NavBar.rtl";
import Footer from "./Footer";
import '../assets/styles/nav/index-hebrew.css';
import Institutertl from "./Institute.rtl";

export default function Accountrtl() {


  const navigate = useNavigate();
  const location = useLocation();

  const localStorageUserId = parseInt(localStorage.getItem('userId'), 10);
  const [userId, setUserId] = useState('');
  const [isOrg, setIsorg] = useState(false)
  let role = localStorage.getItem('role');


  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState('');
  const [gender, setGender] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [orgid, setOrgid] = useState('');

  const [translate, setTransate] = useState()
  const [file, setSelectedFile] = useState(null);

  const [subscribeLastDateString, setSubscribeLastDateString] = useState('');
  const [chaptersCounter, setChaptersCounter] = useState('');
  const [readTimer, setReadTimer] = useState('');

  const [msg, setMsg] = useState('');

  const closePurchase = () => {
    document.getElementById('exampleModal').style.display = 'none';
    document.getElementById('exampleModal').style.opacity = '0';

  }
  const showMenu = () => {
    document.getElementById("mobileMenu").style.right = "0%";
  }
  const closeMenu = () => {
    document.getElementById("mobileMenu").style.right = "-100%";
  }
  const showDropDown = () => {
    document.getElementById("myDropdown").classList.toggle("show");
  }



  const purchasePopUp = (months, price) => {
    //debugger;
    document.getElementById('exampleModal').style.display = 'block';
    document.getElementById('exampleModal').style.opacity = '1';

    document.getElementById('tranzila').setAttribute('src', 'https://direct.tranzila.com/vayikra22/iframenew.php?sum=' + price + '&currency=2&email=' + localStorage.email + '&fail_url_address=https://direct.tranzila.com/vayikra22/iframenew.php&success_url_address=http://104.238.214.197:8087/success');

    localStorage.monthreq = months;
  }

  const Back = () => {
    window.history.go(-1)
  }

  const Logout = () => {
    localStorage.removeItem('loginDate')
    navigate('/');
  }

  const Copyrights = () => {
    navigate('/Privacyrtl');
  }
  const Contacts = () => {
    navigate('/Contactsrtl');
  }
  const Help = () => {
    navigate('/Helprtl');
  }
  const openMyAccount = (evt, tabName) => {

    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent2");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    if (tabName != null) {
      let tabElement = document.getElementById(tabName);
      if (tabElement) {
        document.getElementById(tabName)
        tabElement.style.display = "block";
        evt.currentTarget.className += " active";
      }
    }
  }

  const handleBlur = (e) => {
    if (e.target.value === '') {
      switch (e.target.name.charAt(0) + e.target.name.slice(1)) {
        case 'fname':
          e.target.placeholder = 'שם פרטי';
          break;
        case 'lName':
          e.target.placeholder = 'שם משפחה';
          break;
        case 'email':
          e.target.placeholder = 'איימל';
          break;
        case 'position':
          e.target.placeholder = 'תפקיד';
          break;
        case 'phone':
          e.target.placeholder = 'טלפון';
          break;
        case 'password':
          e.target.placeholder = 'סיסמא חדשה ';
          break;
        case 'password2':
          e.target.placeholder = 'סיסמא חדשה (שוב)';
          break;
        default:
          break;
      }
      // e.target.placeholder = e.target.name.charAt(0).toUpperCase() + e.target.name.slice(1);
    }
  };

  const handleFocus = (e) => {
    e.target.placeholder = '';
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'fname':
        setFirstName(value);
        break;
      case 'lName':
        setLastName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'phone':
        setTel(value);
        break;
      case 'password':
        setPassword(value);
        break;
      case 'password2':
        setPassword2(value);
        break;
      default:
        break;
    }
  };


  const getBase64 = (file) => {

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let filebase64 = reader.result;

      const Register = {
        'Email': email, 'FirstName': firstName, 'LastName': lastName, 'Password': password, 'Phone': tel, 'Phone': tel, 'Gender': gender, 'Country': country, 'Address': address,
        'City': city, 'userId': userId, 'logo': filebase64
      }
      fetch('https://api.readupp.com/Login/UpdateRegisterUser', {
        //        fetch('https://localhost:44318/Login/UpdateRegisterUser',{
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(Register)
      })
        .then(response => response.json())
        .then(data => {
          //debugger;
          if (data.ok) {
            localStorage.loginDate = Date.now()
            setMsg('שינוי נשמר')
          }

        })
        .catch(() => {
          setMsg('Error')
        });
    }
  }



  const convertTimeToHoursAndMinutes = (time) => {
    if (time) {
      const [hours, minutes] = time.split(':').map(Number);
      return `${hours}:${minutes.toString().padStart(2, '0')}`; // Ensure two digits for minutes
    }
    return null; // Optional: return null if time is not provided
  };

  const Save = (event) => {
    //debugger;
    let passwordsMatch = true;
    for (let i = 0; i < Math.max(password.length, password2.length); i++) {
      if (password[i] !== password2[i]) {
        console.log(`Mismatch at character ${i + 1}: "${password[i]}" vs "${password2[i]}"`);
        passwordsMatch = false;
        break;
      }
    }

    if (passwordsMatch) {
      event.preventDefault();

      if (file != null) {
        getBase64(file); // prints the base64 string
      }



      const Register = {
        'Email': email, 'FirstName': firstName, 'LastName': lastName, 'Password': password, 'Phone': tel, 'Phone': tel, 'Gender': gender, 'Country': country, 'Address': address,
        'City': city, 'userId': userId
      }
      fetch('https://api.readupp.com/Login/UpdateRegisterUser', {
        //    fetch('https://localhost:44318/Login/UpdateRegisterUser',{
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(Register)
      })
        .then(response => response.json())
        .then(data => {
          //debugger;
          if (data.ok) {
            localStorage.loginDate = Date.now()
            setMsg('שינוי נשמר')
          }

        })
        .catch(() => {
          setMsg('Error')
        });
    } else {
      event.preventDefault();
      setMsg('סיסמאות שונות !')
    }
  }
  const focusBirthdate = (e) => {
    document.getElementById('birthdate').type = 'date'
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = parseInt(params.get('id'), 10);

    if (id) {
      setUserId(id);
      if (id !== localStorageUserId) {
        setIsorg(false);
      } else if (role === 'Admin' || role === 'Organization Manager') {
        setIsorg(true);
      }
    } else {
      if (role === 'learning central manager') {
        setUserId(localStorageUserId);
      }
      if (role === 'Admin' || role === 'Organization Manager') {
        setUserId(localStorageUserId);
        setIsorg(true);
      }
      else {
        setUserId(localStorageUserId);
      }
    }
  }, [location.search, localStorageUserId, role]);



  useEffect(() => {

    //  fetch('https://localhost:44318/api/UserSettings/GetReadupHe')
    fetch('https://api.readupp.com/api/UserSettings/GetReadupHe')
      .then(async response => {
        const data = await response.json();
        //debugger;
        setTransate(data);


      })
      .catch(error => {

      });
    if (userId) {
      fetch(`https://api.readupp.com/Login/GetUser?userid=${userId}`)
        //  fetch('https://localhost:44318/Login/GetUser?userid='+localStorage.userId)
        .then(async response => {
          const data = await response.json();
          setFirstName(data.firstName || '')
          setLastName(data.lastName || '')
          setAddress(data.address || '')
          setEmail(data.email || '')
          setTel(data.phone || '')
          setCountry(data.country || '')
          setGender(data.gender || '')
          setCity(data.city || '')
          setOrgid(data.orgId || '')
          setPassword(data.password || '')
          setPassword2(data.password || '')
          setSubscribeLastDateString(data.subscribeLastDateString || '')
          setChaptersCounter(data.chaptersCounter || '')
          setReadTimer(data.readTimer || '')
          localStorage.loginDate = Date.now()
          //debugger;
          if (data.birthDate != undefined)
            document.getElementById('birthdate').valueAsDate = new Date(data.birthDate.split('T')[0])
          console.log('1');

          // document.getElementById('langsel').value = data.nativeLang


        })

        .catch(error => {

          console.error('There was an error!', error);
        });


    }


  }, [userId])



  return (<>
    <Navrtl nav="/account" />

    <div style={{ display: 'none' }} className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog subs_modal">
        <div className="modal-content" style={{ marginTop: '20%' }}>
          <div className="container-fluid rtl" >

            <div className="w-100">

              <iframe id="tranzila" src="https://direct.tranzila.com/vayikra22/iframenew.php?sum=1&fail_url_address=http://google.com&notify_url_address=http://google.com">
              </iframe>

              <button className="px-4 py-2 text-white bg_color1 rounded-3 border-0 mt-5" id="closeiframe" onClick={() => closePurchase()}>
                Close
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
    {(!isOrg) && (
      <div className="mainContent2" dir="rtl">
        <div className="contentArea2" style={{ position: 'relative' }}>
          <div className="backPage2">
            <a href="/Usersrtl" style={{ textDecoration: 'none' }}><img src={backArrow} alt="" /></a>
          </div>
          <div className="headerTop2">
            <h2>{translate != null ? translate[109].value : 'my account'} </h2>
            {orgid == 1 || orgid == '' && <div className="timeStamp2">
              <h3>
                {translate != null ? translate[30].value : 'Monthly subscription'} <span className="span1">|</span> &nbsp;
                <span className="span2"> {translate != null ? translate[133].value : 'Active until'}:{subscribeLastDateString}</span>
              </h3>
              <img src={calander} alt="" />
            </div>}
          </div>

          <div className="progress2">
            <div className="myAccountList2">
              <button
                id="defaultOpenAccount"
                className="tablinks active"

                onClick={((event) => openMyAccount(event, 'progress'))}>
                {translate != null ? translate[137].value : 'progress'}
              </button>
              <button
                className="tablinks"
                onClick={((event) => openMyAccount(event, 'editDetail'))}
              >
                {translate != null ? translate[138].value : 'Edit details'}
              </button>
            </div>
            <div className="tabContentTop2">
              <div className="tabcontent2 " id="progress">
                <div className="row2">
                  <div className="card2">
                    <p> {translate != null ? translate[134].value : 'So far you have read'}:</p>
                    <h2>{chaptersCounter} פרקים</h2>
                    <img className="bottomImg2" src={myAccount1} alt="" />
                  </div>
                  <div className="card2">
                    <p> {translate != null ? translate[134].value : 'So far you have read'}:</p>
                    <h2>זמן כולל של {convertTimeToHoursAndMinutes(readTimer)} שעות</h2>
                    <img className="bottomImg2" src={myAccount2} alt="" />
                  </div>
                  <div className="card2">
                    <p>{translate != null ? translate[135].value : 'Your longest streak'}:</p>
                    <h2 style={{ marginBottom: '21px' }}>4 ימים!
                    </h2>
                    <div className="rowTick2">
                      <img src={circleTick} alt="" />
                      <img src={circleTick} alt="" />
                      <img src={circleTick} alt="" />
                      <img src={circleTick} alt="" />
                      <img src={circleTickEmpty} alt="" />
                      <img src={circleTickEmpty} alt="" />
                      <img src={circleTickEmpty} alt="" />
                    </div>
                    <img
                      className="bottomImg2"
                      style={{ bottom: '-20px' }}
                      src={myAccount3}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="tabcontent2" id="editDetail">
                <form action="#" onSubmit={(e) => Save(e)}>
                  <h2>{translate != null ? translate[136].value : 'Edit account information'}:</h2>
                  <input
                    type="text"
                    name="fname"
                    id="fname"
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    value={firstName}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="lName"
                    id="lName"
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    value={lastName}
                    onChange={handleChange}
                  />
                  <input
                    type="email"
                    name="email"
                    id="email"
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    value={email}
                    onChange={handleChange}
                  />
                  <input
                    style={{ marginBottom: '54px' }}
                    type="text"
                    name="phone"
                    id="phone"
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    value={tel || ''}
                    onChange={handleChange}
                  />
                  <h2>{translate != null ? translate[36].value : 'Change password'}</h2>
                  <input
                    type="text"
                    id="password"
                    name="password"
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    value={password}
                    onChange={handleChange}

                  />
                  <input
                    style={{ marginBottom: '45px' }}
                    type="text"
                    name="password2"
                    id="password2"
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    value={password2}
                    onChange={handleChange}

                  />
                  <div className="formBtn2">
                    <button type="submit">{translate != null ? translate[43].value : 'Save'}</button>
                    {msg && <p >{msg}</p>}
                    {/* <button onClick={Save}>Save</button> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
    {(isOrg) && (
      <Institutertl />)}

    <Footer lang={'he'} />



  </>)
}
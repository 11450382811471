import Navrtl from "./NavBar.rtl";
import React, { useState, useEffect } from "react";
import searchIcon from '../assets/img/searchIcon.svg';
import '../assets/styles/nav/index-hebrew.css';
import cancel from '../assets/img/cancel.svg';
import axios from "axios";

function Libaryrtl() {
   
    const [search, setSearch] = useState('');
    const [libraries, setLibraries] = useState([]);
    const localStorageUserId = parseInt(localStorage.getItem('userId'), 10);
    const [libraryID, setLibraryID] = useState()
    const [translate, setTransate] = useState()
    const[visibilitylValidation,setVisibilitylValidation]=useState(''); 
    const [filteredLibraries, setFilteredLibraries] = useState(libraries);
    const [libraryEdit, setLibraryEdit] = useState({
        id: "",
        libraryName: "",
        visibility :""
    })

    const openMyChapter = (evt, tabName) => {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(tabName).style.display = "block";
        evt.currentTarget.className += " active";
    }

    const openLibraryAddModal = () => {
        var addLibraryModalTop = document.getElementById("addLibraryModalTop");
        if (addLibraryModalTop) {
            addLibraryModalTop.style.display = "block";
        }
    }

    const closeLibraryAddModal = () => {
        var addLibraryModalTop = document.getElementById("addLibraryModalTop");
        if (addLibraryModalTop) {
            addLibraryModalTop.style.display = "none";
        }
    }

    // edit modal library 
    const openLibraryEditModal = (library) => {
        var editLibraryModalTop = document.getElementById("editLibraryModalTop");
        if (editLibraryModalTop) {
            editLibraryModalTop.style.display = "block";
        }

        setLibraryEdit({
            id: library.id,
            libraryName: library.libraryName,
            visibility :library.visibility
        })

    }

    const closeLibraryEditModal = () => {
        var editLibraryModalTop = document.getElementById("editLibraryModalTop");
        if (editLibraryModalTop) {
            editLibraryModalTop.style.display = "none";
        }
    }

    // library delete modal
    const openLibraryDeleteModal = (library) => {
        var deleteLibraryModalTop = document.getElementById("deleteLibraryModalTop");
        if (deleteLibraryModalTop) {
            deleteLibraryModalTop.style.display = "block";
        }
        setLibraryEdit({
            id: library.id,
            libraryName: library.libraryName
        })

    }
    const doSearch = () => {
        const tmp = libraries.filter(library =>
            library.libraryName.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredLibraries(tmp);
    };

    const closeLibraryDeleteModal = () => {
        var deleteLibraryModalTop = document.getElementById("deleteLibraryModalTop");
        if (deleteLibraryModalTop) {
            deleteLibraryModalTop.style.display = "none";
        }
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("Name:", name, "Value:", value);
        setLibraryEdit({
            ...libraryEdit,
            [name]: value
        });
    };
    const Delete = (id) => {
        const url = 'https://api.readupp.com/DeleteLibrary?id=' + id;
       // const url = 'https://localhost:44318/DeleteLibrary?id=' + id;

        axios.post(url)
            .then(async response => {

                const data = await response.data;
                if (data) {
                    const updatedlibrary = data;
                    const updatedlibrarys = libraries.filter(library => library.id !== id);

                    setLibraries(updatedlibrarys);
                    closeLibraryDeleteModal();
                }

            });

    }
    const Add = (e) => {
        e.preventDefault();
      let validation=true;
      
      if(!libraryEdit.visibility)
        {
        setVisibilitylValidation("1px solid red")
        validation=false;
        }
       
         if(validation){
        const url = 'https://api.readupp.com/AddLibrary';
       // const url = 'https://localhost:44318/AddLibrary';
        const formData = new FormData();
        formData.append('userId', localStorageUserId);
        formData.append('libraryName', libraryEdit.libraryName);
        formData.append('visibility', libraryEdit.visibility);
      
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
      
        axios.post(url, formData, config)
          .then((response) => {  
            if (response && response.data) {
              const newLibrary = response.data;
              console.log('New library added:', newLibrary);
              
              setLibraries((prevLibraries) => {
                const updatedLibraries = [...prevLibraries, newLibrary];
                console.log('Updated libraries:', updatedLibraries);
                return updatedLibraries;
              });
        setLibraryEdit({ libraryName: ' ', visibility: ' ' });
              closeLibraryAddModal();      
            

            }
          })
          .catch((error) => {
            console.error("There was an error updating the library!", error);
          });
        }
      };
      
        const Save = (e) => {
            

            e.preventDefault();
                
            const url = 'https://api.readupp.com/UpdateLibrary';
          //  const url = 'https://localhost:44318/UpdateLibrary';
            const formData = new FormData();
            formData.append('id', libraryEdit.id);
            formData.append('libraryName', libraryEdit.libraryName);
            formData.append('visibility',libraryEdit.visibility);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };
    
            axios.post(url, formData, config)
                .then((response) => {
                    console.log(response.data);
                    const updatedlibrary = response.data;
    
                    const updatedlibrarys = libraries.map((library) =>
                        library.id === updatedlibrary.id ? updatedlibrary : library
                    );
                  
                    setLibraries(updatedlibrarys);
                    closeLibraryEditModal();
                   
                })
                .catch((error) => {
                    console.error("There was an error updating the library!", error);
                });
        };

        useEffect(() => {
            doSearch();  // Perform search whenever `search` state changes
        }, [search, libraries]); // Re-run search when libraries or search term changes
    
    

    useEffect(() => {
        fetch('https://api.readupp.com/getAllLibrarys?Id=' + localStorage.userId)
            .then(async response => {
                const data = await response.json();
                setFilteredLibraries(data);
                setLibraries(data);
            });

            fetch('https://api.readupp.com/api/UserSettings/GetReadupHe')
            .then(async response => {
                const data = await response.json();
                setTransate(data);
            })
            .catch(error => {
                console.error('There was an error fetching user settings!', error);
            });
    }, []);

    const toggleDropDown = (index) => {
        const dropdown = document.getElementById(`dropdown-${index}`);
        dropdown.style.display = dropdown.style.display === "block" ? "none" : "block";
    };











    return (<>
        <Navrtl nav="/Library" />
        <div dir="rtl" className="mainContent2" style={{ minHeight: '85vh' }}>
            <div className="topHeader2">
                <div className="left2">
                    <h2 style={{ fontSize: '28px', fontWeight: 700 }}>{translate != null ? translate[107].value : 'Libraries'}</h2>
                </div>
                <div className="right2 addLibrary">
                    <button style={{ cursor: 'pointer' }} onClick={openLibraryAddModal}>{translate != null ? translate[156].value : 'Add Library'} + </button>
                </div>
            </div>
            <h2 id="chapterMobile">{translate != null ? translate[107].value : 'Libraries'}</h2>
            <div className="progressChapter addLibTop">
                <div className="search2" style={{ borderBottom: '1px solid #acacac' }}>
                    <h2></h2>
                    <div className="searchInput2">
                            <input
                                className="input2"
                                type="text"
                                name="search"
                                id="search"
                                placeholder="חיפוש ספריה"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <img src={searchIcon} alt="" onClick={doSearch} style={{ cursor: 'pointer' }} />
                        </div>
                </div>

                <div className="contentTab2"  dir="rtl"  style={{ marginBottom: '70px' }}>
                    <div id="chapter1-10" className="tabcontent2">
                        {filteredLibraries.map((library, index) => (
                            <div className="row2" key={library.id}>
                                {/* style={{textAlign:'right'}} */}
                                <div className="left2" >
                                    <h2 >{library.libraryName}</h2>
                                    <div className="progressBar2">
                                        <p className="completed2">{library.number} {translate != null ? translate[111].value : 'Books'}</p>
                                    </div>
                                </div>
                                {library.id === 1 && libraries.find(l => l.id === 1 && localStorageUserId === l.userId) ? (
                                    <div className="circle2" onClick={() => toggleDropDown(index)}>
                                        ...
                                        <div className="dropDownTable2" style={{ display: 'none' }} id={`dropdown-${index}`}>
                                            <div className="inner2">
                                                <div className="square2"></div>
                                                <button onClick={() => openLibraryEditModal(library)}>{translate != null ? translate[154].value : 'Edit Library'}</button>
                                                <button onClick={() => openLibraryDeleteModal(library)}>{translate != null ? translate[155].value : 'Delete Library'}</button>
                                            </div>
                                        </div>
                                    </div>
                                ) : (library.id !== 1 &&
                                    <div className="circle2" onClick={() => toggleDropDown(index)}>
                                        ...
                                        <div className="dropDownTable2" style={{ display: 'none' }} id={`dropdown-${index}`}>
                                            <div className="inner2">
                                                <div className="square2"></div>
                                                <button onClick={() => openLibraryEditModal(library)}>{translate != null ? translate[154].value : 'Edit Library'}</button>
                                                <button onClick={() => openLibraryDeleteModal(library)}>{translate != null ? translate[155].value : 'Delete Library'}</button>
                                            </div>
                                        </div>
                                    </div>)}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>

        <div className="editLibraryModalTop" dir="rtl"  id="editLibraryModalTop">
            <div className="modal-content">
                <div className="modal-header">
                    <span className="close" onClick={closeLibraryEditModal}>
                        <img src={cancel} alt="" /></span>
                    <h2>{translate != null ? translate[154].value : 'Edit Library'}</h2>
                </div>
                <div className="modal-body">
                    <p>{translate != null ? translate[159].value : 'Edit library details'} </p>
                    <form action="#" onSubmit={(e) => Save(e)}>
                        <span style={{float:'right'}}>{translate != null ? translate[160].value : 'library Name'}</span>
                        <input
                            required
                            style={{textAlign:'start'}}
                            type="text"
                            name="libraryName" // Match the state property name
                            id="libraryName"
                            placeholder={libraryEdit.libraryName}
                            value={libraryEdit.libraryName}
                            onChange={handleChange}
                        />
                       <div className="custom-select" >
                                <select onChange={handleChange} name="visibility" 
                                id="visibility" value={libraryEdit.visibility} style={{textAlign:'start'}} >
                                    
                                    <option value="">פרטי / ציבורי</option>
                                    <option value="1">פרטי </option>
                                    <option value="2">ציבורי </option>
                                </select>
                                <div className="select-icon">
                                    <img src="./img/selectDown.svg" alt="" />
                                </div>
                            </div>
                        <button style={{float:'left'}} type="submit">שמור</button>
                    </form>
                </div>
            </div>
        </div>

        <div className="deleteLibraryModalTop" id="deleteLibraryModalTop">
            <div className="modal-content">
                <div className="modal-header">
                    <span className="close" onClick={closeLibraryDeleteModal}><img src={cancel} alt="" /></span>
                    <h2>{translate != null ? translate[155].value : 'Delete Library'}</h2>
                </div>
                <div className="modal-body">
                    <p>האם אתה בטוח שברצונך למחוק את הספרייה: <br />
                        <span>{libraryEdit.libraryName}</span>
                    </p>
                    <h6>פעולה זו תמחק את הספרייה ואת כל הספרים הכלולים והיא בלתי הפיכה!</h6>
                    <div className="btnsDeleteLib">
                        <button className="cancel" onClick={closeLibraryDeleteModal}>ביטול</button>
                        <button className="delete" onClick={() => Delete(libraryEdit.id)}>מחיקה </button>
                    </div>
                </div>
            </div>
        </div>

        <div className="addLibraryModalTop" id="addLibraryModalTop">
                <div className="modal-content">
                    <div className="modal-header">
                        <span className="close" onClick={closeLibraryAddModal}>
                            <img src={cancel} alt="" /></span>
                        <h2>{translate != null ? translate[156].value : 'Add Library'}</h2>
                    </div>
                    <div className="modal-body">
                        <p>הוסף ספריה חדשה </p>
                        <form action="#" onSubmit={(e) => Add(e)}>
                            <input type="text" name="libraryName" id="libraryName" required placeholder="שם ספריה" onChange={handleChange}/>
                            <div className="custom-select" >
                            <select onChange={handleChange} name="visibility"  style={{ border: visibilitylValidation }} required   id="visibility">              
                                    <option >פרטי / ציבורי</option>
                                    <option value="1">פרטי </option>
                                    <option value="2">ציבורי </option>
                                </select>
                                <div className="select-icon">
                                    <img src="./img/selectDown.svg" alt="" />
                                </div>
                            </div>
                            <button  style={{float:'left',cursor:'pointer'}} type="submit">{translate != null ? translate[156].value : 'Add Library'} </button>
                        </form >
                    </div>
                </div>
            </div>


    </>)
}
export default Libaryrtl;
import React, { useRef, useContext, useState, useEffect } from "react";
import search from '../assets/icons/search.svg'
import accessibility from '../assets/icons/accessibility-light.svg'
import hamburger from '../assets/icons/hamburger.svg'
import downArrow from '../assets/icons/downArrow.svg'
import back1 from '../assets/icons/back.svg'
import twoLeft from '../assets/icons/twoLeft.svg'
import set from '../assets/images/set.png'
import sett from '../assets/images/sett.png'
import mar from '../assets/images/mar.png'
import marno from '../assets/images/mar-no.png'
import logolight from '../assets/images/logo-light.png'
import maga1 from '../assets/images/maga1.png'
import re from '../assets/images/re.png'
import playbtn from '../assets/images/play.png'
import pausebtn from '../assets/images/pause.png'
import logo from '../assets/images/logo-light.png'
import question from '../assets/icons/question.svg'
import fwd from '../assets/images/fwd.png'
import maga2 from '../assets/images/maga2.png'
import needHelp from "../assets/images/needHelp.svg"
import '../assets/styles/reader/reader.css';
import startIcon from '../assets/img/startIcon.svg'
import nextIcon from '../assets/img/nextIcon.svg'
import Group from '../assets/img/Group.png'
import backArrow from '../assets/img/backArrow.svg'
import pauseIcon from '../assets/img/pauseIcon.svg'
import celebrate from '../assets/img/5391573_Coll_wavebreak_Balloon_Balloons_1280x720.mp4';
import '../assets/styles/reader/reader.css';
import previousIcon from '../assets/img/previousIcon.svg'
import repeatIcon from '../assets/img/repeatIcon.svg'
import searchNeg from '../assets/img/searchNeg.svg';
import cancel from '../assets/img/cancel.svg';
import searchPos from '../assets/img/searchPos.svg';
import setting from "../assets/img/setting.svg";
import '../assets/styles/nav/home.css';
import '../assets/styles/nav/index-hebrew.css';
import { useNavigate } from 'react-router-dom'

import icon from '../assets/icons/avatar.svg'

import menuCross from "../assets/images/menuCross.svg"
import menuICon from "../assets/images/menuICon.png"
import icons8down48 from "../assets/images/icons8-down-48.png"
import Navrtl from "./NavBar.rtl";
const query = new URLSearchParams(window.location.search);


export default function Playerrtl() {
  const navigate = useNavigate();

  const [chapterId, setChapterId] = useState('');
  const [bookid, setBookid] = useState('');
  const [restart, setRestart] = useState('');
  const [isFinish, setIsFinish] = useState(false);
  const [translate, setTransate] = useState()

  let [fontsize, setFontsize] = useState('30');
  const [capterIndex, setCapterIndex] = useState(0);

  const [stopbtn, setStop] = useState('');
  const [pause, setPause] = useState('');
  let [play, setPlay] = useState('');
  const [backnextbutton, setbacknextbutton] = useState('');

  const [showpause, setshowpause] = useState('');
  const [showplay, setshowplay] = useState('');
  const [showecho, setshowecho] = useState('');


  const [freeze, setfreeze] = useState(false);
  const [first, setfirst] = useState(false);
  const [lightgrey, setlightgrey] = useState(false);
  const [text, setText] = useState('');
  const [chapterNameEng, setChapterNameEng] = useState('');

  const [marker, setMarker] = useState('');
  const [read, setRead] = useState('');
  const [echo, setEcho] = useState('');
  const [timing, setTiming] = useState('0');

  const [speed, setSpeed] = useState('0');

  let [addtime, setAddtime] = useState(0);

  let [repit, setRepit] = useState(false);

  let [langId, setlangId] = useState(localStorage.lang);


  //settings
  const [settingSaved, setSettingSaved] = useState('');
  const [showTiming, setShowTiming] = useState('none');



  const SaveSettings = () => {
    //debugger;
    localStorage.timing = timing;
    stopEcho();
    const UserSettings = { 'userId': localStorage.userId, 'echo': echo, 'read': read, 'marker': marker }
    //debugger;
    //   fetch('https://localhost:44318/api/UserSettings/UpdateUserSettings',{
    fetch('https://api.readupp.com/api/UserSettings/UpdateUserSettings', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(UserSettings)
    })
      .then(response => response.json())
      .then(data => {

        setSettingSaved('Setting saved!')
        window.location.reload();



      })
      .catch(() => {

      });
  }
  const ChangeRead = (read) => {
    setRead(read)
    if (read == 'timing')
      setShowTiming('block')
    else
      setShowTiming('none')

    document.getElementById('continuous').style.border = "";
    document.getElementById('auto').style.border = "";
    document.getElementById('timing').style.border = "";
    document.getElementById('manual').style.border = "";
    document.getElementById(read).style.border = "1px solid black";

    if (read == 'continuous') {
      setshowecho('none')
      ChangeEcho('noecho')
    }
    else {
      setshowecho('block')
    }
  }
  const showMenu = () => {
    document.getElementById("mobileMenu").style.right = "0%";
  }
  const closeMenu = () => {
    document.getElementById("mobileMenu").style.right = "-100%";
  }
  const showDropDown = () => {
    document.getElementById("myDropdown").classList.toggle("show");
  }


  const ChangeEcho = (echo) => {

    setEcho(echo)
    document.getElementById('echo').style.border = "";
    document.getElementById('noecho').style.border = "";
    document.getElementById(echo).style.border = "1px solid black";
  }

  const ChangeMarker = (marker) => {
    setMarker(marker)
    document.getElementById('yellow').style.backgroundColor = "lightgrey";
    document.getElementById('none').style.backgroundColor = "lightgrey";
    document.getElementById('underline').style.backgroundColor = "lightgrey";
    document.getElementById('grey').style.backgroundColor = "lightgrey";
    document.getElementById(marker).style.backgroundColor = "#44c6cb";
  }
  const selectNumber = (number) => {
    setTiming(number); // Update the state with the selected timing
    var dropdownContent = document.getElementById("dropdownContent");
    // if (dropdownContent) {
    //   dropdownContent.style.display = "none";
    // }
    var timer = document.getElementById("timer");
    if (timer) {
      timer.style.display = "none";
    }
  }
  const hideTimer = () => {
    var timer = document.getElementById("timer");
    timer.style.display = "none";
  }

  const toggleDropdownNumber = () => {
    var dropdownContent = document.getElementById("dropdownContent");
    var timer = document.getElementById("timer");
    if (dropdownContent.style.display === "block") {
      dropdownContent.style.display = "none";
    } else {
      dropdownContent.style.display = "block";
      timer.style.display = "block";
    }
  }


  let playRef = useRef();


  var sentence;
  var loopCount;
  var micid = 0;
  const myInterval = useRef();


  useEffect(() => {

    localStorage.button = "";
    //  fetch('https://localhost:44318/api/UserSettings/GetReadupHe')
    fetch('https://api.readupp.com/api/UserSettings/GetReadupHe')
      .then(async response => {
        const data = await response.json();
        //debugger;
        setTransate(data);

      })
      .catch(error => {

      });


    localStorage.lightgrey = 'false'
    localStorage.spoke = "false"
    localStorage.fetch = 'false'
    //debugger;
    setSpeed('0');
    if (localStorage.timing == undefined)
      setTiming(0)
    else
      setTiming(localStorage.timing);
    setShowTiming('none')
    setChapterId(getSearchParams('id'))
    setBookid(getSearchParams('bookid'))
    setRestart(getSearchParams('restart'))
    setCapterIndex(parseInt(getSearchParams('index')) + 1)
    setshowpause('none');
    setshowplay('inline');

    setFontsize(30)
    chooseMarker('yellow');
    chooseRead('manual');
    chooseEcho('noecho');
    if (getSearchParams('restart') != null && getSearchParams('restart') == 'true') {
      stop()
    }
    else
      refresh()

    getUserSettings()

    window.strm = [];

  }, [])

  const getUserSettings = () => {
    //   fetch('https://localhost:44318/api/UserSettings/GetUserSettings?userId='+localStorage.userId)
    fetch('https://api.readupp.com/api/UserSettings/GetUserSettings?userId=' + localStorage.userId)
      .then(async response => {
        const data = await response.json();
        if (data != null) {
          ChangeMarker(data.marker);
          ChangeRead(data.read);
          ChangeEcho(data.echo);
        }

        localStorage.loginDate = Date.now()
      })
      .catch(error => {
        this.setState({ errorMessage: error.toString() });
        console.error('There was an error!', error);
      });
  }

  const Copyrights = () => {
    navigate('/Privacyrtl');
  }
  const Contacts = () => {
    navigate('/Contactsrtl');
  }
  const Help = () => {
    navigate('/Helprtl');
  }
  const chooseMarker = (color) => {
    setMarker(color);
  }
  const chooseRead = (read) => {
    setRead(read);
  }
  const chooseEcho = (echo) => {
    setEcho(echo);
  }

  const increaseText = () => {

    if (fontsize < 73) {
      fontsize = parseInt(fontsize) + 2
      setFontsize(fontsize)
    }
  }
  const decreaseText = () => {


    if (fontsize > 16) {
      fontsize = parseInt(fontsize) - 2
      setFontsize(fontsize)
    }
  }
  const showBooks = () => {
    navigate('/booksrtl')
  }
  const mainLoop = () => {
    setText(sentence.transcript);
    document.getElementById('carteSoudCtrl').src = "/Files/" + localStorage.userId + "/" + sentence.id + "_" + sentence.words + ".mp3";
    document.getElementById('carteSoudCtrl').load();
    document.getElementById('carteSoudCtrl').play();
    document.getElementById('carteSoudCtrl').onplaying = (event) => {
      highlight(sentence.wordsNavigation);
    };
  }
  const highlight = (words) => {
    var duration = document.getElementById('carteSoudCtrl').duration;
    var time = duration * 1000;
    time = 0;
    myLoop(time);

  }

  const lang = (val) => {
    const id = getSearchParams('id')
    const bookid = getSearchParams('bookid')
    if (val == 1)
      navigate('/player?id=' + id + '&bookid=' + bookid);
    else
      navigate('/playerrtl?id=' + id + '&bookid=' + bookid);
  }

  const myLoop = (time) => {
    setTimeout(() => onLoop(), time);
  }

  const onLoop = () => {

    setfreeze(false);
    if (loopCount < sentence.wordsNavigation.length) {
      let time = 0;
      if (localStorage.play == 'true') {
        time = (sentence.wordsNavigation[loopCount].endTime - sentence.wordsNavigation[loopCount].startTime) * 1000;
        if (loopCount == sentence.wordsNavigation.length - 1)
          if (time < 300)
            time = 800;
        if (marker == "yellow")
          color_word(sentence.transcript.trim().split(' ')[loopCount], loopCount, 'yellow');
        else if (marker == "grey")
          color_word(sentence.transcript.trim().split(' ')[loopCount], loopCount, 'lightgrey');
        else if (marker == "underline")
          color_word(sentence.transcript.trim().split(' ')[loopCount], loopCount, 'underline');
        else
          color_word(sentence.transcript.trim().split(' ')[loopCount], loopCount, 'none');

        loopCount++;
        myLoop(time);             //  ..  again which will trigger another 
        localStorage.automatic = null;
      }
    }
    else {
      document.getElementById('text').innerHTML = "<span  class='lighterthengrey'>" + sentence.transcript + "</span>";
      setText(sentence.transcript);
      localStorage.lightgrey = 'true'
      setlightgrey(true);

      localStorage.play = 'false'

      //    myInterval.current = setTimeout(() => {

      if (read == "manual") {


        setshowplay('inline')
        setshowpause('none')
        //   document.getElementById('carteSoudCtrl').pause();
        localStorage.button = "pause";
        setAddtime(0);
        clearInterval(myInterval.current);

        if (echo == "echo") {
          //debugger;
          //  window.navigator.mediaDevices.getUserMedia({ audio: true }).then(onSuccess, onError);
          startEcho();






        }
        else if (echo == "noecho") {

          var aaa = (new window.webkitSpeechRecognition)
          aaa.continuous = true;
          aaa.start();




        }

      }
      else if (read == "timing") {

        if (echo == "echo") {
          //window.navigator.mediaDevices.getUserMedia({ audio: true }).then(onSuccess, onError);
          startEcho();
        }


        clearInterval(myInterval.current);
        //debugger;


        setshowplay('none')
        setshowpause('inline')
        setlightgrey(true)
        if (echo == "echo") {
          //debugger;
          //  window.navigator.mediaDevices.getUserMedia({ audio: true }).then(onSuccess, onError);
          startEcho();





        }
        else if (echo == "noecho") {

          var aaa = (new window.webkitSpeechRecognition)
          aaa.continuous = true;
          aaa.start();




        }

        addtime = parseInt(localStorage.timing) * 1000;
        setTimeout(function () { nextLine(); }, addtime);

      }
      else if (read == "continuous") {
        if (echo == "echo") {
          //  window.navigator.mediaDevices.getUserMedia({ audio: true }).then(onSuccess, onError);
          startEcho();
        }
        setAddtime(0)
        clearInterval(myInterval.current);

        setshowplay('none')
        setshowpause('inline')
        if (localStorage.fetch == 'false')
          nextLine();
      }
      else if (read == "auto") {
        setAddtime(0)
        setshowplay('none')
        setshowpause('inline')
        clearInterval(myInterval.current);

        if (echo == "echo") {
          //debugger;
          //  window.navigator.mediaDevices.getUserMedia({ audio: true }).then(onSuccess, onError);
          startEcho();


          navigator.mediaDevices.getUserMedia({
            audio: true
          })
            .then(stream => {
              detectSilence(stream, onSilence, onSpeak, 2000, -50);
              // do something else with the stream
            }).catch(e => console.log(e));



        }
        else if (echo == "noecho") {

          var aaa = (new window.webkitSpeechRecognition)
          aaa.continuous = true;
          aaa.start();

          navigator.mediaDevices.getUserMedia({
            audio: true
          })
            .then(stream => {
              detectSilence(stream, onSilence, onSpeak, 2000, -40);
              // do something else with the stream
            }).catch(e => console.log(e));


        }


      }
      //   }, 1000 + (addtime * 1000));
    }
  }
  const toggleDropdownTime = () => {
    document.getElementById("dropContent").classList.toggle("show");
  }


  const pausenow = () => {

    localStorage.button = "pause";

    setshowpause('none');
    setshowplay('inline');
    localStorage.play = 'pause'
    localStorage.pause = 'true'
    document.getElementById('carteSoudCtrl').pause();
    stopEcho();
    setPlay(false);
    localStorage.play = 'false'
    setfirst(false);
    setPause(true);
    stopEcho();

  }

  const sleep = (milliseconds) => {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }


  const color_word = (word, loopCount, color) => {
    if (sentence.wordsNavigation.length == 1 || (word != undefined && sentence.transcript.trim() != word.trim())) {
      let words = sentence.transcript.trim().split(' ');
      if (loopCount == 999 || loopCount == words.length) {
        //debugger;  
        document.getElementById('text').innerHTML = "<span style='font-family: Heebo Regular' class='lighterthengrey'>" + sentence.transcript + "</span>";
        setText(sentence.transcript);
        localStorage.lightgrey = 'true'
        setlightgrey(true);
        sleep(1000);
      }
      else {
        words = words.map(function (item, index) { return index == loopCount ? "<span class='" + color + "' [class] ='" + color + "'}>" + word + '</span>' : item });
        let new_words = words.join(' ');

        document.getElementById('text').innerHTML = new_words;
      }


    }
    else {

      sleep(1000);
      if (text.includes('(')) {
        document.getElementById('text').innerHTML = "<span style='font-family: Heebo Regular'>" + text.split(')')[0] + ")" + "</span>" + "<span style='font-family: Heebo Regular' class='lighterthengrey'>" + text.split(')')[1] + "</span>";
        setText(text.split(')')[0]);
        localStorage.lightgrey = 'true'
        setlightgrey(true);
      }
      else
        document.getElementById('text').innerHTML = "<span style='font-family: Heebo Regular' class='lighterthengrey'>" + text + "</span>";
      localStorage.lightgrey = 'true'
      setlightgrey(true);
      document.getElementById('text').innerHTML = document.getElementById('text').innerHTML.replace("[class] ='yellow'", "").replace("class='yellow'", "");
      document.getElementById('text').innerHTML = document.getElementById('text').innerHTML.replace("[class] ='underline'", "").replace("class='underline'", "");
      document.getElementById('text').innerHTML = document.getElementById('text').innerHTML.replace("[class] ='none'", "").replace("class='none'", "");
    }
  }

  const dorestart = () => {
    setfreeze(false);
    loopCount = 0;
    if (play == true && localStorage.fetch == 'false') {
      setPause(false);
      setPlay(true);
      localStorage.play = 'true';
      setfirst(false);
      setlightgrey(false);
      localStorage.lightgrey = 'false'
      localStorage.fetch = 'true'

      //   fetch('https://localhost:44318/api/Player/GetNextLine?bookid='+bookid+'&chapterid='+query.get('id')+"&micid=0&userid="+localStorage.userId+'&repit='+repit+'&speed='+speed)
      fetch('https://api.readupp.com/api/Player/GetNextLine?bookid=' + bookid + '&chapterid=' + getSearchParams('id') + "&micid=0&userid=" + localStorage.userId + '&repit=' + repit + '&speed=' + speed)
        .then(async response => {
          localStorage.fetch = 'false';

          if (localStorage.pause == 'false') {
            setRepit(false)
            repit = false;
            const data = await response.json();
            if(data.transcript== "finish") {
              setIsFinish(true);
            }
            sentence = data
            localStorage.play = 'true'
            setChapterNameEng(data.chapterNameEng)
            micid = sentence.id;
            if (sentence.wordsNavigation.length > 0)
              setTimeout(() => mainLoop(), sentence.wordsNavigation[sentence.wordsNavigation.length - 1].endTime + 0.1);
            //  else
            //  setTimeout(() => mainLoop());

            localStorage.loginDate = Date.now()
          }
          else {
            setRepit(true);
          }
        })
        .catch(error => {
          setIsFinish(true);
          //   this.setState({ errorMessage: error.toString() });
          console.error('There was an error!', error);
        });

    }
  }


  const stop = () => {
    stopEcho();
    clearInterval(myInterval.current);
    localStorage.button = "stop";
    setPause(false);
    setPlay(false);
    setRepit(true);
    localStorage.play = 'false';
    setbacknextbutton(false);
    loopCount = 0;
    document.getElementById('carteSoudCtrl').src = 'aaa';
    document.getElementById('audio').pause();

    //   fetch('https://localhost:44318/api/Player/PrePareAudioStop?chapterid='+query.get('id')+"&usrId="+localStorage.userId)
    fetch('https://api.readupp.com/api/Player/PrePareAudioStop?chapterid=' + getSearchParams('id') + "&usrId=" + localStorage.userId)
      .then(async response => {
        document.getElementById('text').innerHTML = "";
        localStorage.speed = '0'
        if (restart != null && restart == 'true') {

          refresh();
        }
        else {
          document.getElementById('carteSoudCtrl').src = "/Files/" + localStorage.userId + "/" + sentence.id + "_" + sentence.words + ".mp3";
          document.getElementById('carteSoudCtrl').load();
        }

        localStorage.loginDate = Date.now()
      })
      .catch(error => {
        this.setState({ errorMessage: error.toString() });
        console.error('There was an error!', error);
      });
  }

  const getSearchParams = (k) => {
    var p = {};
    window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (s, k, v) { p[k] = v })
    return k ? p[k] : p;
  }


  const refresh = () => {
    //debugger;

    //   fetch('https://localhost:44318/api/Chapters/GetChapterById?id='+getSearchParams('id'))
    fetch('https://api.readupp.com/api/Chapters/GetChapterById?id=' + getSearchParams('id'))
      .then(async response => {
        const data = await response.json();
        setChapterNameEng(data.chapterNameEng)

        //  fetch('https://localhost:44318/api/Player/PrePareAudio?chapterid='+getSearchParams('id')+'&usrId='+localStorage.userId)
        fetch('https://api.readupp.com/api/Player/PrePareAudio?chapterid=' + getSearchParams('id') + '&usrId=' + localStorage.userId)
          .then(async response => {

            //   fetch('https://localhost:44318/api/UserSettings/GetUserSettings?userId='+localStorage.userId)
            fetch('https://api.readupp.com/api/UserSettings/GetUserSettings?userId=' + localStorage.userId)
              .then(async response => {
                const data = await response.json();
                if (data != null) {
                  chooseMarker(data.marker);
                  chooseRead(data.read);
                  chooseEcho(data.echo);
                }


              })
              .catch(error => {


              });


          })
          .catch(error => {


          });

        localStorage.loginDate = Date.now()
      })
      .catch(error => {
        this.setState({ errorMessage: error.toString() });
        console.error('There was an error!', error);
      });
  }


  const Back = () => {
    stopEcho();
    window.history.go(-1)
  }

  const Profile = () => {
    if (localStorage.role == "Admin" || localStorage.role == "Organization Manager")
      navigate('/institutertl')
    else
      navigate('/accountrtl')
  }

  const Logout = () => {
    localStorage.removeItem('loginDate')
    navigate('/loginrtl');
  }


  const OpenPopUp = () => {
    //debugger;
    if (localStorage.button != "play") {
      stopEcho();
      document.getElementById('settingModal').style.display = 'block';
      document.getElementById('settingModal').style.opacity = '1';
      setSettingSaved('')
    }


  }

  const ClosePopUp = () => {
    //debugger;
    document.getElementById('settingModal').style.display = 'none';
    document.getElementById('settingModal').style.opacity = '0';

  }

  const startEcho = () => {
    if (navigator.getUserMedia) {
      navigator.getUserMedia(
        {
          audio: {
            latency: 0,
            echoCancellation: false,
            mozNoiseSuppression: true,
            mozAutoGainControl: false
          }
        },
        function (stream) {

          let aCtx = new AudioContext();
          let microphone = aCtx.createMediaStreamSource(stream);
          var destination = aCtx.destination;
          microphone.connect(destination);
          window.strm.push(stream);
        },
        function () { console.log("Error 003.") }
      );
    }
  }
  const stopEcho = () => {
    //debugger;
    if (window.strm != null && window.strm != undefined && window.strm.length > 0) {
      for (var i = 0; i < window.strm.length; i++) {
        if (window.strm[i].getTracks() != null)
          for (var j = 0; j < window.strm[i].getTracks().length; j++) {
            window.strm[i].getTracks()[j].stop()
          }
      }

      //  window.strm.getTracks().forEach(t=>window.strm.removeTrack(t))
      window.strm = [];
    }
  }

  const detectSilence = (stream, onSoundEnd = _ => { }, onSoundStart = _ => { }, silence_delay = 500, min_decibels = -80) => {
    const ctx = new AudioContext();
    const analyser = ctx.createAnalyser();
    const streamNode = ctx.createMediaStreamSource(stream);
    streamNode.connect(analyser);
    analyser.minDecibels = min_decibels;

    const data = new Uint8Array(analyser.frequencyBinCount); // will hold our data
    let silence_start = performance.now();
    let triggered = false; // trigger only once per silence event

    function loop(time) {
      requestAnimationFrame(loop); // we'll loop every 60th of a second to check
      analyser.getByteFrequencyData(data); // get current data
      if (data.some(v => v)) { // if there is data above the given db limit
        if (triggered) {
          triggered = false;
          onSoundStart();
        }
        silence_start = time; // set it to now
      }
      if (!triggered && time - silence_start > silence_delay) {
        onSoundEnd();
        triggered = true;
      }
    }
    loop();
  }
  const selectValue = (event) => {
    event.preventDefault();

    const selectedValue = event.target.textContent; // Displayed text
    const selectedDataValue = event.target.getAttribute('data-value'); // Actual value

    // Update the state and localStorage with the actual value
    setSpeed(selectedDataValue);
    localStorage.speed = selectedDataValue;

    // Update the button text with the displayed value
    const dropdownButton = document.querySelector('.dropDownBtn');
    dropdownButton.textContent = selectedValue;

    // Remove active class from all items
    const items = document.querySelectorAll('.dropContent a');
    items.forEach(item => {
      item.classList.remove('activeBtn');
    });

    // Add active class to the selected item
    event.target.classList.add('activeBtn');

    // Hide the dropdown
    document.getElementById("dropContent").classList.remove("show");
  };


  var spoke = false;
  const onSilence = () => {
    // console.log('silence\n');
    if ((read == "auto" && localStorage.pause == 'false')) {
      nextLine();

      localStorage.spoke = "false"
      localStorage.lightgrey = "false"
    }
    stopEcho();
  }
  const onSpeak = () => {
    if (read == "auto" && (localStorage.lightgrey == "true" && localStorage.play == "false")) {

      localStorage.spoke = "true"
      // console.log('speaking\n');
      localStorage.lightgrey = 'false'
      setlightgrey(false);
    }
  }

  const playnow = () => {
    stopEcho();
    localStorage.button = "play";
    var playPauseText = document.getElementById("playPauseText");
    playPauseText.textContent = "הפסק";
    localStorage.pause = 'false'
    localStorage.play = 'true'
    //  $('.playbutton').attr('aria-hidden', 'true');
    //   $('.pausebutton').attr('aria-hidden', 'false');
    stopEcho();
    if (pause) {
      document.getElementById('carteSoudCtrl').play();
      localStorage.play = 'true'
      setPause(false);
      setshowpause('inline');
      setshowplay('none');
      setPlay(true);
      play = true;
    }
    else {
      if (!freeze) {
        setPause(false);
        setshowpause('inline');
        setshowplay('none');
        setPlay(true);
        play = true;
        localStorage.play = 'true';
        stopEcho();
        //   $('#play').hide();
        //   $('#pause').show();

        if (lightgrey == true || first == true)
          nextLine();
        else
          dorestart();
      }
    }
  }

  let back = () => {


    if (!backnextbutton && (pause || play == false || localStorage.play == 'false')) {
      setbacknextbutton(true);
      setfirst(true)
      setPause(false);

      //    fetch('https://localhost:44318/api/Player/Back?bookid='+bookid+'&chapterid='+getSearchParams('id')+"&userid="+localStorage.userId)
      fetch('https://api.readupp.com/api/Player/Back?bookid=' + bookid + '&chapterid=' + getSearchParams('id') + "&userid=" + localStorage.userId)
        .then(async response => {
          const data = await response.json();
          if (data != null) {
            sentence = data;
            loopCount = 0;
            setbacknextbutton(false);
            document.getElementById('text').innerHTML = sentence.transcript;
            setText(sentence.transcript);
          }

          localStorage.loginDate = Date.now()
        })
        .catch(error => {
          this.setState({ errorMessage: error.toString() });
          console.error('There was an error!', error);
        });


    }


  }

  let next = () => {
    stopEcho();

    if (!backnextbutton && (pause || play == false || localStorage.play == 'false')) {
      setbacknextbutton(true);
      setPause(false);
      setfirst(true)

      //   fetch('https://localhost:44318/api/Player/Next?bookid='+bookid+'&chapterid='+query.get('id')+"&userid="+localStorage.userId)
      fetch('https://api.readupp.com/api/Player/Next?bookid=' + bookid + '&chapterid=' + getSearchParams('id') + "&userid=" + localStorage.userId)
        .then(async response => {
          const data = await response.json();
          if (data != null) {
            sentence = data;
            loopCount = 0;
            setbacknextbutton(false);
            document.getElementById('text').innerHTML = sentence.transcript;
            setText(sentence.transcript);
          }

          localStorage.loginDate = Date.now()
        })
        .catch(error => {
          this.setState({ errorMessage: error.toString() });
          console.error('There was an error!', error);
        });


    }


  }

  const nextLine = () => {
    loopCount = 0;
    stopEcho();
    if (localStorage.fetch == 'false') {
      setlightgrey(false)
      localStorage.lightgrey = 'false'
      localStorage.fetch = 'true';

      //  fetch('https://localhost:44318/api/Player/GetNextLine?bookid='+bookid+'&chapterid='+query.get('id')+"&micid=0&userid="+localStorage.userId+'&repit='+repit+'&speed='+speed)
      fetch('https://api.readupp.com/api/Player/GetNextLine?bookid=' + bookid + '&chapterid=' + getSearchParams('id') + "&micid=0&userid=" + localStorage.userId + '&repit=' + repit + '&speed=' + localStorage.speed)
        .then(async response => {
          localStorage.fetch = 'false';
          if (localStorage.pause == 'false') {
           
            setRepit(false)
            repit = false;
            const data = await response.json(); 
            if(data.transcript== "finish") {
              setIsFinish(true);
            }
            sentence = data;
            localStorage.play = 'true'

            setChapterNameEng(data.chapterNameEng)
            micid = sentence.id;
            if (sentence.wordsNavigation.length > 0)
              setTimeout(() => mainLoop(), sentence.wordsNavigation[sentence.wordsNavigation.length - 1].endTime + 0.1);
            //   else
            //   setTimeout(() => mainLoop());

            localStorage.loginDate = Date.now()
          }
          else {
            setRepit(true);
          }
        })
        .catch(error => {
          this.setState({ errorMessage: error.toString() });
          console.error('There was an error!', error);
        });
    }



    console.log('fin');

  }


  return (<>

    <div className="settingModal2" id="settingModal" dir="ltr">
      <div className="innerContent2">
        <div className="headerModal2">
          <img className="cancel2" onClick={ClosePopUp} src={cancel} alt="" />
          <h1>{translate != null ? translate[38].value : 'Reading Preferences'}</h1>
        </div>
        <div className="settingModal2-body2">
          <div className="left2">
            <div className="headText2">
              <h2>{translate != null ? translate[164].value : 'Reading:'}</h2>
            </div>
            <form action="#">
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}>
                <div style={{ flexDirection: 'row-reverse' }}>
                  <input type="radio" id="auto" name="radio-group" checked={read === 'auto'} onChange={() => ChangeRead('auto')} />
                  <label for="auto">{translate != null ? translate[165].value : 'According to users progress:'}
                    <span>{translate != null ? translate[166].value : 'System listens and adjusts pace accordingly'}</span></label>
                </div>
                <div>
                  <input type="radio" id="manual" name="radio-group" checked={read === 'manual'} onChange={() => ChangeRead('manual')} />
                  <label for="manual">{translate != null ? translate[47].value : 'Manual'}
                    <span>{translate != null ? translate[167].value : 'Continue reading by clicking "Continue"'}</span></label>
                </div>
                <div>
                  <input type="radio" id="timing" name="radio-group" checked={read === 'timing'} onChange={() => ChangeRead('timing')} />
                  <label for="timing">{translate != null ? translate[44].value : 'Timing:'}
                    <span style={{ display: 'block' }}>
                      <div className="inner">
                        {translate != null ? translate[175].value : '  A new sentence every"'}
                        <div className="custom-dropdown" id="numberDropdown">
                          <div className="selected-value" onClick={toggleDropdownNumber}>
                            {timing}
                          </div>
                          <div className="inner" id="timer">
                            <div className="dropdown-content" id="dropdownContent">
                              <div className="headerTimer">
                                <h3>{translate != null ? translate[175].value : 'Choose the timing'}Choose the timing</h3>
                                <img onClick={hideTimer} src="./img/Union.png" alt="" />
                              </div>
                              <p className="paraG">A new sentence will be read every:</p>
                              {[7, 10, 13, 16, 19, 22].map(time => (
                                <div key={time} onClick={() => selectNumber(time)}>
                                  <input
                                    type="radio"
                                    id={`${time}Sec`}
                                    name="time-group"
                                    checked={timing === time}
                                    readOnly
                                  />
                                  <label htmlFor={`${time}Sec`}>{time}  {translate != null ? translate[45].value : 'seconds'}</label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        {translate != null ? translate[45].value : 'seconds'}
                      </div>
                    </span></label>
                </div>
                <div style={{ marginBottom: '35px;' }}>
                  <input type="radio" id="continuous" name="radio-group" checked={read === 'continuous'} onChange={() => ChangeRead('continuous')} />
                  <label for="continuous"> {translate != null ? translate[169].value : 'Continuous reading'}
                    <span>{translate != null ? translate[170].value : 'Reading only, without user repeating'}</span></label>
                </div>
              </div>
              <div className="headText2">
                <h2>{translate != null ? translate[162].value : 'The text markup style'}</h2>
              </div>
              <div className="marker">
                <p>
                  <input type="radio" id="yellow" name="radio-text" checked={marker === 'yellow'} onChange={() => ChangeMarker('yellow')} />
                  <label for="yellow">{translate != null ? translate[93].value : 'Yellow'}
                  </label>
                </p>
                <div className="mark"><span className="yellow" > הלך</span> הילד</div>
              </div>
              <div className="marker">
                <p>
                  <input type="radio" id="grey" name="radio-text" checked={marker === 'grey'} onChange={() => ChangeMarker('grey')} />
                  <label for="grey">{translate != null ? translate[94].value : 'Gray'}
                  </label>
                </p>
                <div className="mark"><span className="gray" >  הלך</span> הילד</div>
              </div>

              <div className="marker">
                <p>
                  <input type="radio" id="underline" name="radio-text" checked={marker === 'underline'} onChange={() => ChangeMarker('underline')} />
                  <label for="underline">{translate != null ? translate[95].value : 'Underline'}
                  </label>
                </p>
                <div className="mark"><span className="underline" > הלך</span>הילד </div>
              </div>

              <div className="marker">
                <p>
                  <input type="radio" id="none" name="radio-text" checked={marker === 'none'} onChange={() => ChangeMarker('none')} />
                  <label for="none">{translate != null ? translate[96].value : 'None'}
                  </label>
                </p>
                <div className="mark"><span className="noMarker" > הלך</span> הילד</div>
              </div>

            </form>
          </div>
          <div className="left2 leftForMobile">
            <div className="headText2">
              <h2>{translate != null ? translate[164].value : 'Reading:'}</h2>
              <p>({translate != null ? translate[163].value : 'Only while using headphones:'})</p>
            </div>
            <form action="#"  style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}>
              <p>
                <input type="radio" id="echo" name="forEcho" checked={echo === 'echo'}
                  onChange={() => ChangeEcho('echo')} />
                <label for="echo">{translate != null ? translate[171].value : 'With echo:'}
                  <span>{translate != null ? translate[173].value : 'Users hears themselves reading in headphones'} </span></label>
              </p>

              <p>
                <input type="radio" id="noecho" name="forEcho" checked={echo === 'noecho'}
                  onChange={() => ChangeEcho('noecho')} />
                <label for="noecho">{translate != null ? translate[172].value : 'Without echo:'}
                  <span>{translate != null ? translate[174].value : 'No user echoing while reading'} </span></label>
              </p>

            </form>

          </div>
        </div>
        <div className="btnSave2">
          <button className="save" onClick={(e) => SaveSettings(e)}>שמור</button>
        </div>

      </div>
    </div>



    <Navrtl nav={'player?id=' + chapterId + '&bookid=' + bookid + '&index=' + getSearchParams('index')} />
    <div className="mainContent readingTop">
      <div className="contentArea readingBookTop" >
        <div className="backPage">
          <a onClick={() => Back()} style={{ textDecoration: 'none' }}><img src={backArrow} alt="" /></a>
        </div>
        <p className="hideParaMobile">{chapterNameEng}| פרק {capterIndex}</p>
        {isFinish && (
          <div className="celebrate">
            <h2>!טוב מאד ! סיית את הפרק </h2>
            <video autoPlay loop muted playsInline width="100%">
              <source src={celebrate} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}

        {!isFinish && <div className="player" dir="ltr">
          <a href="./index.html" className="group"><img src={Group} alt="" /></a>
          <h1 id="text" dir={langId === '1' ? 'rtl' : 'ltr'} style={{ fontSize: fontsize }}> {text}</h1>
          <div className="footerPlayer">
            <div className="left">
              <img src={searchNeg} alt="" onClick={() => decreaseText()} />
              <img src={searchPos} alt="" onClick={() => increaseText()} />
            </div>
            <div className="center">
              <div className="previous">
                <div className="inner">
                  <img src={previousIcon} alt="" onClick={back} />
                  <p>הקודם</p>
                </div>
              </div>
              <div className="repeat">
                <div className="inner">
                  <img src={repeatIcon} alt="" onClick={stop} />
                  <p>חזרה</p>
                </div>
              </div>
              <div className="start" >
                <div className="inner">
                  {/* <img id="playPauseIcon" src={startIcon} alt=""onClick={playnow} />*/}

                  <img
                    style={{ display: showplay }} onClick={playnow}
                    src={startIcon}
                    alt=""
                  />
                  <img
                    style={{ display: showpause }} onClick={pausenow}
                    src={pauseIcon}
                    alt=""
                  />  <p id="playPauseText">המשך</p>
                </div>

              </div>
              <div className="next">
                <div className="inner">
                  <img src={nextIcon} alt="" onClick={next} />
                  <p>הבא</p>
                </div>
              </div>
            </div>
            {/* <div className="center_part position-absolute">
              <img
                className="swiper-button-prev" style={{ 'width': '12%' }}
                src={previousIcon}
                alt="" onClick={() => back()}
              />
              <img
                className="ms-3"
                src={repeatIcon}
                alt="" onClick={() => stop()}
              />
              <img
                className="mx-2 playbutton" style={{ display: showplay }} onClick={() => playnow()}
                src={startIcon}
                alt=""
              />
              <img
                className="mx-2 playbutton" style={{ display: showpause }} onClick={() => pausenow()}
                src={pauseIcon}
                alt=""
              />
              <img
                className="swiper-button-next" style={{ 'width': '12%' }}
                src={nextIcon}
                alt="" onClick={() => next()}
              />
            </div> */}
            <div className="right">
              {/* <!-- <div className="customSelect">
              <select name="speed" id="speed">
                <option value="1">1x</option>
                <option value="2">1.2x</option>
                <option value="3">1.1x</option>
                <option value="4">1x</option>
                <option value="5">0.8x</option>
                <option value="6">0.7x</option>
                <option value="7">0.6x</option>
              </select>
            </div> --> */}
              <div className="customSelect">
                <button className="dropDownBtn" onClick={toggleDropdownTime}>
                  1x {/* Default value displayed */}
                </button>
                <div id="dropContent" className="dropContent">
                  <a href="#" onClick={(event) => selectValue(event)} data-value="6">1.25x</a>
                  <a href="#" onClick={(event) => selectValue(event)} data-value="5">1.125x</a>
                  <a href="#" className="activeBtn" onClick={(event) => selectValue(event)} data-value="0">1x</a>
                  <a href="#" onClick={(event) => selectValue(event)} data-value="1">0.875x</a>
                  <a href="#" onClick={(event) => selectValue(event)} data-value="2">0.75x</a>
                  <a href="#" onClick={(event) => selectValue(event)} data-value="3">0.625x</a>
                </div>
              </div>
              {/* <select className="zoom d-none d-md-block" onChange={(e)=>{
                          setSpeed(e.target.value);
                          localStorage.speed=e.target.value; 
                        }
                        
                        }>
                          <option value="6">1.25x
                          <img
                            className="ms-2"
                            src={downArrow}
                            alt=""
                          /></option>
                          <option value="5">1.125x
                          <img
                            className="ms-2"
                            src={downArrow}
                            alt=""
                          /></option>
                          <option value="0" selected>1x
                          <img
                            className="ms-2"
                            src={downArrow}
                            alt=""
                          /></option>
                          <option value="1">0.875x
                          <img
                            className="ms-2"
                            src={downArrow}
                            alt=""
                          /></option>
                          <option value="2">0.75x
                          <img
                            className="ms-2"
                            src={downArrow}
                            alt=""
                          /></option>
                          <option value="3">0.625x
                          <img
                            className="ms-2"
                            src={downArrow}
                            alt=""
                          /></option>
                        </select> */}

              <img style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={OpenPopUp} src={setting} alt="" />
            </div>
          </div>
        </div>
        }
        <div className="progressPlayer">
          <p>Progress</p>
          <div className="progress">
            <div style={{ width: ' 50%' }} className="innerLine"></div>
          </div>
        </div>
        {/* <!-- <div className="practiceEnd">
        <h4>
          Practice <br />
          End
        </h4>
      </div> --> */}
      </div>
    </div>
    {/* <div className="page_container">
      <div className="body_container">
        <div className="page_inner body_part py-4 px-4">
          <div className="container-fluid position-relative">
            <div className="back_btn d-none d-md-block">
              <img style={{'width': '50px','margin-left': '33%'}} src={back1} alt="" onClick={() => Back()}/>
            </div>

            <div className="text-center mb-4">
              <p className="f22 mb-0 fw400 color1">{chapterNameEng}</p>
            </div>



            <div className="swiper mySwiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide px-1 px-md-4 py-3">
                  <div
                    className="slide_box d-flex justify-content-center align-items-center position-relative"
                  >
                    <h1 className="text-center fw600" id="text" style={{fontSize: fontsize}}>{text}</h1>

                    <div
                      className="w-100 position-absolute bottom-0 d-flex justify-content-between py-2 px-3" 
                    >
                      <div>
                        <img src={maga2} alt="" onClick={() => decreaseText()}/>
                        <img onClick={() => increaseText()}
                          className="ms-2"
                          src={maga1}
                          alt=""
                        />
                      </div>
                      <div className="center_part position-absolute">
                        <img
                          className="swiper-button-prev" style={{'width':'12%'}}
                          src={re}
                          alt="" onClick={() => back()}
                        />
                        <img
                          className="ms-3"
                          src={twoLeft}
                          alt="" onClick={() => stop()}
                        />
                        <img
                          className="mx-2 playbutton" style={{display:showplay}} onClick={() => playnow()}
                          src={playbtn}
                          alt=""
                        />
                        <img
                          className="mx-2 playbutton" style={{display:showpause}} onClick={() => pausenow()}
                          src={pausebtn}
                          alt=""
                        />
                        <img
                          className="swiper-button-next" style={{'width':'12%'}}
                          src={fwd}
                          alt=""  onClick={() => next()}
                        />
                      </div>
                      <div className="d-flex gap-3 align-items-center">
                        <button
                          className="border-0"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal" onClick={() => OpenPopUp()}
                        >
                          <img src={set} alt="" />
                        </button>

                        <select className="zoom d-none d-md-block" onChange={(e)=>{
                          setSpeed(e.target.value);
                          localStorage.speed=e.target.value; 
                        }
                        
                        }>
                          <option value="6">1.25x
                          <img
                            className="ms-2"
                            src={downArrow}
                            alt=""
                          /></option>
                          <option value="5">1.125x
                          <img
                            className="ms-2"
                            src={downArrow}
                            alt=""
                          /></option>
                          <option value="0" selected>1x
                          <img
                            className="ms-2"
                            src={downArrow}
                            alt=""
                          /></option>
                          <option value="1">0.875x
                          <img
                            className="ms-2"
                            src={downArrow}
                            alt=""
                          /></option>
                          <option value="2">0.75x
                          <img
                            className="ms-2"
                            src={downArrow}
                            alt=""
                          /></option>
                          <option value="3">0.625x
                          <img
                            className="ms-2"
                            src={downArrow}
                            alt=""
                          /></option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
    <audio controls id="carteSoudCtrl" aria-hidden="true">
      <source src="/Files/play.mp3" type="audio/mpeg"></source>
      Your browser does not support the audio element.
    </audio>
    <audio id="audio" controls autoplay aria-hidden="true"></audio>






  </>
  )

}